import {LocationTime} from './LocationTime';
import {Location} from './Location';
import {EnumContinuousType} from './index';

export class CustomerLocation {
  city: string;
  zipcode: string;
  street: string;
  country: string;
  housenumber: string;
  location: Location = new Location();
  locationtimes: Array<LocationTime> = new Array<LocationTime>();
  locationweektype: EnumContinuousType;
}
