import { Injectable } from '@angular/core';

@Injectable()
export class PrintEditorEditModeService {

  isPrintEditMode = false;

  public setPrintEditMode(status: boolean) {
    this.isPrintEditMode = status;
  }
}
