import { CustomerLocation } from './CustomerLocation';

export class Customer {
  name: string;
  firstname: string;
  lastname: string;
  id: number;
  email: string;
  emailinvoice: string;
  // website: string;
  location: CustomerLocation;
  taxnumber: string;
  iban: string;
  bankowner: string;
  debit: false;
  invoicefrequenzmonth: 1;
}
