import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class TranslationExtention {

  infoSaveSucessText = 'Speichern erfolgreich';
  infoUnsaveText = '';
  infoBranchChangeText = '';
  infoContiniousRulesText = '';
  allRuleNeedsDayText = '';
  infoTemplateText = '';
  headerAllergenText = '';
  headerWeekplanningModal = '';
  branch = '';
  for= '';
  allergenText = '';
  mobileapp = '';
  snippet = '';
  webapp = '';
  all = '';

  constructor(private translate: TranslateService) {
    translate.get('UI.MOBILEAPP').subscribe((res: string) => {
      (this.mobileapp = res);
    });
    translate.get('UI.SNIPPET').subscribe((res: string) => {
      (this.snippet = res);
    });
    translate.get('UI.WEBAPP').subscribe((res: string) => {
      (this.webapp = res);
    });
    translate.get('UI.ALL').subscribe((res: string) => {
      (this.all = res);
    });
    translate.get('UI.AUSWAHLALLERGENE').subscribe((res: string) => {
      (this.allergenText = res);
    });
    translate.get('INFO.UNSAVECHANGES').subscribe((res: string) => {
      (this.infoUnsaveText = res);
    });
    translate.get('INFO.TABCHANGE').subscribe((res: string) => {
      (this.infoBranchChangeText = res);
    });
    translate.get('INFO.SAVESUCCESS').subscribe((res: string) => {
      (this.infoSaveSucessText = res);
    });
    translate.get('INFO.CONTINUOUSRULESCOMPONENT').subscribe((res: string) => {
      (this.infoContiniousRulesText = res);
    });

    translate.get('INFO.ALLRULESNEEDSDAY').subscribe((res: string) => {
      (this.allRuleNeedsDayText = res);
    });
    translate.get('INFO.TEMPLATECOMPONENT').subscribe((res: string) => {
      (this.infoTemplateText = res);
    });
    translate.get('UI.AUSWAHLALLERGENE').subscribe((res: string) => {
      (this.headerAllergenText = res);
    });
    translate.get('UI.WEEKPLANNINGFORWEEK').subscribe((res: string) => {
      (this.headerWeekplanningModal = res);
    });
    translate.get('UI.BRANCH').subscribe((res: string) => {
      (this.branch = res);
    });
    translate.get('UI.FOR').subscribe((res: string) => {
      (this.for = res);
    });
  }
}
