import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders} from '@angular/common/http';

import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import {NgxEmailPassAuthProviderConfig} from './email-pass-auth.options';
import {NgxAuthResult, NgxDataResult} from '../services/auth.service';
import {NgxAbstractAuthProvider} from './abstract-auth.provider';
import {getDeepFromObject} from '../../../pages/components/auth/helpers';

import {NGX_AUTH_TOKEN_WRAPPER_TOKEN} from '../../../pages/components/auth/auth.options';
import {TranslateService} from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

@Injectable()
export class NgxEmailPassAuthProvider extends NgxAbstractAuthProvider {
  errorText = 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut oder kontaktieren Sie den Kundendienst';

  headers = new HttpHeaders({
    'Access-Control-Allow-Headers': 'Content-Type, Accept',
    'Content-Type': 'application/json',
    'Accept': 'application/json'});

  protected defaultConfig: NgxEmailPassAuthProviderConfig = {

    baseEndpoint: environment.APIEndpoint,
    login: {
      alwaysFail: false,
      rememberMe: true,
      endpoint: '/authenticate',
      method: 'post',
      redirect: {
        success: 'pages/components/Uebersicht',
        failure: null,
      },
      defaultErrors: ['Login/Email ist nicht korrekt, bitte versuchen Sie es erneut.'],
      defaultMessages: ['Erfolgreich angemeldet.'],
    },
    register: {
      alwaysFail: false,
      rememberMe: true,
      endpoint: '/register',
      method: 'post',
      redirect: {
        success: './',
        failure: null,
      },
      defaultErrors: [this.errorText],
      defaultMessages: ['Sie haben sich erfolgreich registriert, Sie bekommen in Kürze einen Link zur Aktivierung per Mail,'],
    },
    logout: {
      alwaysFail: false,
      endpoint: '/logout',
      method: 'post',
      redirect: {
        success: '/',
        failure: null,
      },
      defaultErrors: [this.errorText],
      defaultMessages: ['Sie sind erfolgreich abgemeldet.'],
    },
    requestPass: {
      endpoint: '/requestPassword',
      method: 'post',
      defaultErrors: [this.errorText],
      defaultMessages: ['Die Anweisungen zum Zurücksetzen des Passworts wurden an Ihre E-Mail gesendet.'],
      redirect: {
        success: '/login',
        failure: null,
      },
    },
    resetPass: {
      endpoint: '/resetPassword',
      method: 'post',
      redirect: {
        success: '/login',
        failure: null,
      },
      resetPasswordTokenKey: 'reset_password_token',
      defaultErrors: [this.errorText],
      defaultMessages: ['Ihr Passwort wurde erfolgreich geändert.'],
    },
    getCustomer: {
      alwaysFail: false,
      endpoint: '/getCustomer',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    updateCustomer: {
      alwaysFail: false,
      endpoint: '/updateCustomer',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    getBranchesForUser: {
      alwaysFail: false,
      endpoint: '/getBranchesForUser',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    updateBranches: {
      alwaysFail: false,
      endpoint: '/updateBranches',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    updateBranchName: {
      alwaysFail: false,
      endpoint: '/updateBranchName',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    getTemplateMealsForUser: {
      alwaysFail: false,
      endpoint: '/getTemplateMealsForUser',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    updateTemplateMeals: {
      alwaysFail: false,
      endpoint: '/updateTemplateMeals',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    getMealsForCustomerForWeek: {
      alwaysFail: false,
      endpoint: '/getMealsForCustomerForWeek',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    updateMealsForCustomerForWeek: {
      alwaysFail: false,
      endpoint: '/updateMealsForCustomerForWeek',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    getPackagesForBranches: {
      alwaysFail: false,
      endpoint: '/getPackagesForBranches',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    updatePackagesForBranches: {
      alwaysFail: false,
      endpoint: '/updatePackagesForBranches',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    getContinuousRulesForBranches: {
      alwaysFail: false,
      endpoint: '/getContinuousRulesForBranches',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    updateContinuousRulesForBranches: {
      alwaysFail: false,
      endpoint: '/updateContinuousRulesForBranches',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    getInvoices: {
      alwaysFail: false,
      endpoint: '/getInvoices',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    getInvoiceForDownload: {
      alwaysFail: false,
      endpoint: '/getInvoiceForDownload',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    getFacebookAuthUrl: {
      alwaysFail: false,
      endpoint: '/getFacebookAuthUrl',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    getTwitterAuthUrl: {
      alwaysFail: false,
      endpoint: '/getTwitterAuthUrl',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    getStatisticDataInDays: {
      alwaysFail: false,
      endpoint: '/getStatisticInDaysForBranches',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    getStatisticDataInWeeks: {
      alwaysFail: false,
      endpoint: '/getStatisticInWeeksForBranches',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    getStatisticDataInMonths: {
      alwaysFail: false,
      endpoint: '/getStatisticInMonthsForBranches',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    getDashboardStatistic: {
      alwaysFail: false,
      endpoint: '/dashboardstatistic',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    getMealplanPDF: {
      alwaysFail: false,
      endpoint: '/getWeekplanPdf',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    getMealplanURLs: {
      alwaysFail: false,
      endpoint: '/getWeekPlanUrls',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    saveMealPlanPrint: {
      alwaysFail: false,
      endpoint: '/saveMealPlanPrint',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    saveMealPlanPrintPdf: {
      alwaysFail: false,
      endpoint: '/saveMealPlanPrintPdf',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    getPrintConfigByBranch: {
      alwaysFail: false,
      endpoint: '/getPrintConfigsByBranch',
      method: 'post',
      defaultErrors: [this.errorText],
    },

    getPrintConfigByName: {
      alwaysFail: false,
      endpoint: '/getPrintConfigByName',
      method: 'post',
      defaultErrors: [this.errorText],
    },

    getPrintConfigNamesByBranch: {
      alwaysFail: false,
      endpoint: '/getPrintConfigNamesByBranch',
      method: 'post',
      defaultErrors: [this.errorText],
    },


    updatePrintConfigByBranch: {
      alwaysFail: false,
      endpoint: '/updatePrintConfigs',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    saveBranchImage: {
      alwaysFail: false,
      endpoint: '/saveBranchImage',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    deleteBranchImage: {
      alwaysFail: false,
      endpoint: '/deleteBranchImage',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    getManagerMessages: {
      alwaysFail: false,
      endpoint: '/getManagerMessages',
      method: 'post',
      defaultErrors: [this.errorText],
    },
    token: {
      key: 'token',
      getter: (module: string, res: HttpResponse<Object>) => getDeepFromObject(res.body, this.getConfigValue('token.key')),
    },
    errors: {
      key: 'message',
      getter: (module: string, res: HttpErrorResponse) => getDeepFromObject(res.error,
        this.getConfigValue('errors.key'),
        this.getConfigValue(`${module}.defaultErrors`)),
    },
    messages: {
      key: 'data.messages',
      getter: (module: string, res: HttpResponse<Object>) => getDeepFromObject(res.body,
        this.getConfigValue('messages.key'),
        this.getConfigValue(`${module}.defaultMessages`)),
    },
  };

  constructor(protected http: HttpClient,
              private route: ActivatedRoute,
              @Inject(NGX_AUTH_TOKEN_WRAPPER_TOKEN) protected token: any,
              private translate: TranslateService) {
    super();
    translate.get('INFO.ERRORDEFAULT').subscribe((res: string) => {
      (this.errorText = res);
    });
  }

  // Login
  authenticate(data?: any): Observable<NgxAuthResult> {
    const method = this.getConfigValue('login.method');
    const url = this.getActionEndpoint('login');

    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxAuthResult(
          true,
          res,
          this.getConfigValue('login.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('login', res),
          this.getConfigValue('token.getter')('login', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('login', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxAuthResult(
            false,
            res,
            this.getConfigValue('login.redirect.failure'),
            errors,
          ));
      });
  }

  register(data?: any): Observable<NgxAuthResult> {
    const method = this.getConfigValue('register.method');
    const url = this.getActionEndpoint('register');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxAuthResult(
          true,
          res,
          this.getConfigValue('register.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('register', res),
          this.getConfigValue('token.getter')('register', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('register', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxAuthResult(
            false,
            res,
            this.getConfigValue('register.redirect.failure'),
            errors,
          ));
      });
  }

  requestPassword(data?: any): Observable<NgxAuthResult> {
    const method = this.getConfigValue('requestPass.method');
    const url = this.getActionEndpoint('requestPass');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxAuthResult(
          true,
          res,
          this.getConfigValue('requestPass.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('requestPass', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('requestPass', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxAuthResult(
            false,
            res,
            this.getConfigValue('requestPass.redirect.failure'),
            errors,
          ));
      });
  }

  resetPassword(data: any = {}): Observable<NgxAuthResult> {
    const tokenKey = this.getConfigValue('resetPass.resetPasswordTokenKey');
    data[tokenKey] = this.route.snapshot.queryParams[tokenKey];

    const method = this.getConfigValue('resetPass.method');
    const url = this.getActionEndpoint('resetPass');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxAuthResult(
          true,
          res,
          this.getConfigValue('resetPass.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('resetPass', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('resetPass', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxAuthResult(
            false,
            res,
            this.getConfigValue('resetPass.redirect.failure'),
            errors,
          ));
      });
  }

  logout(): Observable<NgxAuthResult> {
    return Observable.of({})
      .switchMap((res: any) => {
          return Observable.of(res);
      })
      .map((res) => {
        return new NgxAuthResult(
          true,
          res,
          this.getConfigValue('logout.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('logout', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('logout', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxAuthResult(
            false,
            res,
            this.getConfigValue('logout.redirect.failure'),
            errors,
          ));
      });
  }

  // holt die Customer Daten
  getCustomer(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('getCustomer.method');
    const url = this.getActionEndpoint('getCustomer');

    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response'})
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          [],
          [],
          this.getConfigValue('messages.getter')('getCustomer', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('getCustomer', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('getCustomer.redirect.failure'),
            errors,
          ));
      });
  }

  updateCustomer(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('updateCustomer.method');
    const url = this.getActionEndpoint('updateCustomer');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('updateCustomer.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('updateCustomer', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('updateCustomer', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('updateCustomer.redirect.failure'),
            errors,
          ));
      });
  }

  getBranchesForUser(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('getBranchesForUser.method');
    const url = this.getActionEndpoint('getBranchesForUser');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('getBranchesForUser.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('getBranchesForUser', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('getBranchesForUser', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('getBranchesForUser.redirect.failure'),
            errors,
          ));
      });
  }

  updateBranches(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('updateBranches.method');
    const url = this.getActionEndpoint('updateBranches');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('updateBranches.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('updateBranches', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('updateBranches', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('updateBranches.redirect.failure'),
            errors,
          ));
      });
  }

  updateBranchName(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('updateBranchName.method');
    const url = this.getActionEndpoint('updateBranchName');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('updateBranchName.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('updateBranchName', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('updateBranchName', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('updateBrancheName.redirect.failure'),
            errors,
          ));
      });
  }

  getTemplateMealsForUser(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('getTemplateMealsForUser.method');
    const url = this.getActionEndpoint('getTemplateMealsForUser');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('getTemplateMealsForUser.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('getTemplateMealsForUser', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('getTemplateMealsForUser', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('getTemplateMealsForUser.redirect.failure'),
            errors,
          ));
      });
  }

  updateTemplateMeals(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('updateTemplateMeals.method');
    const url = this.getActionEndpoint('updateTemplateMeals');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('updateTemplateMeals.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('updateTemplateMeals', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('updateTemplateMeals', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('updateTemplateMeals.redirect.failure'),
            errors,
          ));
      });
  }

  getMealsForCustomerForWeek(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('getMealsForCustomerForWeek.method');
    const url = this.getActionEndpoint('getMealsForCustomerForWeek');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('getMealsForCustomerForWeek.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('getMealsForCustomerForWeek', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('getMealsForCustomerForWeek', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('getMealsForCustomerForWeek.redirect.failure'),
            errors,
          ));
      });
  }

  updateMealsForCustomerForWeek(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('updateMealsForCustomerForWeek.method');
    const url = this.getActionEndpoint('updateMealsForCustomerForWeek');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('updateMealsForCustomerForWeek.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('updateMealsForCustomerForWeek', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('updateMealsForCustomerForWeek', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('updateMealsForCustomerForWeek.redirect.failure'),
            errors,
          ));
      });
  }

  getPackagesForBranches(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('getPackagesForBranches.method');
    const url = this.getActionEndpoint('getPackagesForBranches');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('getPackagesForBranches.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('getPackagesForBranches', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('getPackagesForBranches', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('getPackagesForBranches.redirect.failure'),
            errors,
          ));
      });
  }

  updatePackagesForBranches(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('updatePackagesForBranches.method');
    const url = this.getActionEndpoint('updatePackagesForBranches');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('updatePackagesForBranches.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('updatePackagesForBranches', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('updatePackagesForBranches', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('updatePackagesForBranches.redirect.failure'),
            errors,
          ));
      });
  }

  getContinuousRulesForBranches(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('getContinuousRulesForBranches.method');
    const url = this.getActionEndpoint('getContinuousRulesForBranches');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('getContinuousRulesForBranches.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('getContinuousRulesForBranches', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('getContinuousRulesForBranches', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('getContinuousRulesForBranches.redirect.failure'),
            errors,
          ));
      });
  }

  updateContinuousRulesForBranches(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('updateContinuousRulesForBranches.method');
    const url = this.getActionEndpoint('updateContinuousRulesForBranches');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('updateContinuousRulesForBranches.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('updateContinuousRulesForBranches', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('updateContinuousRulesForBranches', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('updateContinuousRulesForBranches.redirect.failure'),
            errors,
          ));
      });
  }

  getInvoices(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('getInvoices.method');
    const url = this.getActionEndpoint('getInvoices');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('getInvoices.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('getInvoices', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('getInvoices', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('getInvoices.redirect.failure'),
            errors,
          ));
      });
  }

  getInvoiceForDownload(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('getInvoiceForDownload.method');
    const url = this.getActionEndpoint('getInvoiceForDownload');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response', responseType: 'blob'})
      .map((res) => {
        return new NgxDataResult(
          true,
          new Blob([res.body], { type: 'application/pdf' }),
          this.getConfigValue('getInvoiceForDownload.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('getInvoiceForDownload', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('getInvoiceForDownload', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('getInvoiceForDownload.redirect.failure'),
            errors,
          ));
      });
  }

  getFacebookAuthUrl(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('getFacebookAuthUrl.method');
    const url = this.getActionEndpoint('getFacebookAuthUrl');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('getFacebookAuthUrl.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('getFacebookAuthUrl', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('getFacebookAuthUrl', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('getFacebookAuthUrl.redirect.failure'),
            errors,
          ));
      });
  }

  getTwitterAuthUrl(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('getTwitterAuthUrl.method');
    const url = this.getActionEndpoint('getTwitterAuthUrl');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('getTwitterAuthUrl.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('getTwitterAuthUrl', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('getTwitterAuthUrl', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('getTwitterAuthUrl.redirect.failure'),
            errors,
          ));
      });
  }

  getStatisticDataInDays(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('getStatisticDataInDays.method');
    const url = this.getActionEndpoint('getStatisticDataInDays');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('getStatisticDataInDays.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('getStatisticDataInDays', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('getStatisticDataInDays', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('getStatisticDataInDays.redirect.failure'),
            errors,
          ));
      });
  }

  getStatisticDataInWeeks(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('getStatisticDataInWeeks.method');
    const url = this.getActionEndpoint('getStatisticDataInWeeks');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('getStatisticDataInWeeks.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('getStatisticDataInWeeks', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('getStatisticDataInWeeks', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('getStatisticDataInWeeks.redirect.failure'),
            errors,
          ));
      });
  }

  getStatisticDataInMonths(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('getStatisticDataInMonths.method');
    const url = this.getActionEndpoint('getStatisticDataInMonths');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('getStatisticDataInMonths.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('getStatisticDataInMonths', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('getStatisticDataInMonths', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('getStatisticDataInMonths.redirect.failure'),
            errors,
          ));
      });
  }

  getDashboardStatistic(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('getDashboardStatistic.method');
    const url = this.getActionEndpoint('getDashboardStatistic');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('getDashboardStatistic.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('getDashboardStatistic', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('getDashboardStatistic', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('getDashboardStatistic.redirect.failure'),
            errors,
          ));
      });
  }

  getMealplanPDF(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('getMealplanPDF.method');
    const url = this.getActionEndpoint('getMealplanPDF');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response', responseType: 'blob'})
      .map((res) => {
        return new NgxDataResult(
          true,
          new Blob([res.body], { type: 'application/pdf' }),
          this.getConfigValue('getMealplanPDF.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('getMealplanPDF', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('getMealplanPDF', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('getMealplanPDF.redirect.failure'),
            errors,
          ));
      });
  }

  saveMealPlanPrint(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('saveMealPlanPrint.method');
    const url = this.getActionEndpoint('saveMealPlanPrint');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response'})
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('saveMealPlanPrint.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('saveMealPlanPrint', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('saveMealPlanPrint', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('saveMealPlanPrint.redirect.failure'),
            errors,
          ));
      });
  }

  saveMealPlanPrintPdf(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('saveMealPlanPrintPdf.method');
    const url = this.getActionEndpoint('saveMealPlanPrintPdf');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response'})
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('saveMealPlanPrintPdf.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('saveMealPlanPrintPdf', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('saveMealPlanPrintPdf', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('saveMealPlanPrintPdf.redirect.failure'),
            errors,
          ));
      });
  }

  getMealplanURLs(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('getMealplanURLs.method');
    const url = this.getActionEndpoint('getMealplanURLs');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('getMealplanURLs.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('getMealplanURLs', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('getMealplanURLs', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('getMealplanURLs.redirect.failure'),
            errors,
          ));
      });
  }

  updatePrintConfigByBranch(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('updatePrintConfigByBranch.method');
    const url = this.getActionEndpoint('updatePrintConfigByBranch');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('updatePrintConfigByBranch.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('updatePrintConfigByBranch', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('updatePrintConfigByBranch', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('updatePrintConfigByBranch.redirect.failure'),
            errors,
          ));
      });
  }

  getPrintConfigByBranch(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('getPrintConfigByBranch.method');
    const url = this.getActionEndpoint('getPrintConfigByBranch');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('getPrintConfigByBranch.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('getPrintConfigByBranch', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('getPrintConfigByBranch', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('getPrintConfigByBranch.redirect.failure'),
            errors,
          ));
      });
  }

  getPrintConfigNamesByBranch(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('getPrintConfigNamesByBranch.method');
    const url = this.getActionEndpoint('getPrintConfigNamesByBranch');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('getPrintConfigNamesByBranch.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('getPrintConfigNamesByBranch', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('getPrintConfigNamesByBranch', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('getPrintConfigNamesByBranch.redirect.failure'),
            errors,
          ));
      });
  }

  getPrintConfigByName(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('getPrintConfigByName.method');
    const url = this.getActionEndpoint('getPrintConfigByName');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response' })
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('getPrintConfigByName.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('getPrintConfigByName', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('getPrintConfigByName', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('getPrintConfigByName.redirect.failure'),
            errors,
          ));
      });
  }

  saveBranchImage(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('saveBranchImage.method');
    const url = this.getActionEndpoint('saveBranchImage');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response'})
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('saveBranchImage.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('saveBranchImage', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('saveBranchImage', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('saveBranchImage.redirect.failure'),
            errors,
          ));
      });
  }

  deleteBranchImage(data?: any): Observable<NgxDataResult> {
    const method = this.getConfigValue('deleteBranchImage.method');
    const url = this.getActionEndpoint('deleteBranchImage');
    return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response'})
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('deleteBranchImage.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('deleteBranchImage', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('deleteBranchImage', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('deleteBranchImage.redirect.failure'),
            errors,
          ));
      });
  }
  getManagerMessages(data?: any): Observable<NgxDataResult> {
      const method = this.getConfigValue('getManagerMessages.method');
      const url = this.getActionEndpoint('getManagerMessages');
      return this.http.request(method, url, { body: data, headers: this.headers, observe: 'response'})
      .map((res) => {
        return new NgxDataResult(
          true,
          res,
          this.getConfigValue('getManagerMessages.redirect.success'),
          [],
          this.getConfigValue('messages.getter')('getManagerMessages', res));
      })
      .catch((res) => {
        let errors = [];
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('getManagerMessages', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxDataResult(
            false,
            res,
            this.getConfigValue('getManagerMessages.redirect.failure'),
            errors,
          ));
      });
    }

  protected getActionEndpoint(action: string): string {
    const actionEndpoint: string = this.getConfigValue(`${action}.endpoint`);
    const baseEndpoint: string = this.getConfigValue('baseEndpoint');
    return baseEndpoint + actionEndpoint;
  }
}
