import {Injectable, Optional, Inject, Injector} from '@angular/core';

import {Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';

import {NgxAbstractAuthProvider} from '../providers/abstract-auth.provider';
import {NgxAuthJWTToken, NgxAuthSimpleToken, NgxTokenService} from './token.service';
import {NGX_AUTH_PROVIDERS_TOKEN} from '../../../pages/components/auth/auth.options';

export class NgxAuthResult {

  protected token: any;
  protected errors: string[] = [];
  protected messages: string[] = [];

  // TODO pass arguments in options object
  constructor(protected success: boolean,
    protected response?: any,
    protected redirect?: any,
    errors?: any,
    messages?: any,
    token?: NgxAuthJWTToken) {
    this.errors = this.errors.concat([errors]);
    if (errors instanceof Array) {
      this.errors = errors;
    }

    this.messages = this.messages.concat([messages]);
    if (messages instanceof Array) {
      this.messages = messages;
    }

    this.token = token;
  }

  getResponse(): any {
    return this.response;
  }

  getTokenValue(): any {
    return this.token;
  }

  replaceToken(token: NgxAuthSimpleToken): any {
    this.token = token;
  }

  getRedirect(): any {
    return this.redirect;
  }

  getErrors(): string[] {
    return this.errors.filter(val => !!val);
  }

  getMessages(): string[] {
    return this.messages.filter(val => !!val);
  }

  isSuccess(): boolean {
    return this.success;
  }

  isFailure(): boolean {
    return !this.success;
  }
}

export class NgxDataResult {

  protected errors: string[] = [];
  protected messages: string[] = [];

  // TODO pass arguments in options object
  constructor(protected success: boolean,
              protected response?: any,
              protected redirect?: any,
              errors?: any,
              messages?: any) {

    this.errors = this.errors.concat([errors]);
    if (errors instanceof Array) {
      this.errors = errors;
    }

    this.messages = this.messages.concat([messages]);
    if (messages instanceof Array) {
      this.messages = messages;
    }
  }

  getResponse(): any {
    return this.response;
  }

  getRedirect(): any {
    return this.redirect;
  }

  getErrors(): string[] {
    return this.errors.filter(val => !!val);
  }

  getMessages(): string[] {
    return this.messages.filter(val => !!val);
  }

  isSuccess(): boolean {
    return this.success;
  }

  isFailure(): boolean {
    return !this.success;
  }
}

@Injectable()
export class NgxAuthService {

  constructor(protected tokenService: NgxTokenService,
              protected injector: Injector,
              @Optional() @Inject(NGX_AUTH_PROVIDERS_TOKEN) protected providers = {}) {
  }

  protected isBranchUser: boolean;

  getIsBranchUser(): boolean  {
    return (sessionStorage.getItem('role') === 'branchuser');
    // return this.isBranchUser;
  }
  /**
   * Retrieves current authenticated token stored
   * @returns {Observable<any>}
   */
  getToken(): Observable<NgxAuthJWTToken> {
    return this.tokenService.get();
  }

  /**
   * returns true if tokenExpDate is valid
   * @returns {Observable<boolean>}
   */
  tokenExpDateCheck(): Observable<boolean> {
    const current_time = Date.now().valueOf() / 1000;
    return this.getToken().map(token => !!(token.getTokenExpDate().valueOf() < current_time));
  }

  /**
   * Returns true if auth token is presented in the token storage
   * @returns {Observable<any>}
   */
  isAuthenticated(): Observable<boolean> {
    return this.getToken().map((token: NgxAuthJWTToken) => token.isValid());
  }

  /**
   * Returns tokens stream
   * @returns {Observable<any>}
   */
  onTokenChange(): Observable<NgxAuthJWTToken> {
    return this.tokenService.tokenChange();
  }

  /**
   * Returns authentication status stream
   *  // check exp date for JWT token
   * @returns {Observable<any>}
   */
  onAuthenticationChange(): Observable<boolean> {
    return this.onTokenChange().map(token => !!(token && token.getValue() && this.tokenExpDateCheck()));
  }

  /**
   * Authenticates with the selected provider
   * Stores received token in the token storage
   *
   * Example:
   * authenticate('email', {email: 'email@example.com', password: 'test'})
   *
   * @param provider
   * @param data
   * @returns {Observable<NgxAuthResult>}
   */
  authenticate(provider: string, data?: any): Observable<NgxAuthResult> {
    return this.getProvider(provider).authenticate(data)
      .switchMap((result: NgxAuthResult) => {
        if (result.isSuccess() && result.getTokenValue()) {
          sessionStorage.setItem('role', result['response']['body']['role']);
       
          return this.tokenService.set(result.getTokenValue())
            .switchMap(_ => this.tokenService.get())
            .map(token => {
              result.replaceToken(token);
              return result;
            })
        }

        return Observable.of(result);
      });
  }

  /**
   * Registers with the selected provider
   * Stores received token in the token storage
   *
   * Example:
   * register('email', {email: 'email@example.com', name: 'Some Name', password: 'test'})
   *
   * @param provider
   * @param data
   * @returns {Observable<NgxAuthResult>}
   */
  register(provider: string, data?: any): Observable<NgxAuthResult> {
    return this.getProvider(provider).register(data)
      .switchMap((result: NgxAuthResult) => {
        if (result.isSuccess() && result.getTokenValue()) {
          return this.tokenService.set(result.getTokenValue())
            .switchMap(_ => this.tokenService.get())
            .map(token => {
              result.replaceToken(token);
              return result;
            });
        }

        return Observable.of(result);
      });
  }

  /**
   * Sign outs with the selected provider
   * Removes token from the token storage
   *
   * Example:
   * logout('email')
   *
   * @param provider
   * @returns {Observable<NgxAuthResult>}
   */
  logout(provider: string): Observable<NgxAuthResult> {
    return this.getProvider(provider).logout()
      .do((result: NgxAuthResult) => {
        if (result.isSuccess()) {
          this.tokenService.clear().subscribe(() => { });
        }
      });
  }

  /**
   * Sends forgot password request to the selected provider
   *
   * Example:
   * requestPassword('email', {email: 'email@example.com'})
   *
   * @param provider
   * @param data
   * @returns {Observable<NgxAuthResult>}
   */
  requestPassword(provider: string, data?: any): Observable<NgxAuthResult> {
    return this.getProvider(provider).requestPassword(data);
  }

  /**
   * Tries to reset password with the selected provider
   *
   * Example:
   * resetPassword('email', {newPassword: 'test'})
   *
   * @param provider
   * @param data
   * @returns {Observable<NgxAuthResult>}
   */
  resetPassword(provider: string, data?: any): Observable<NgxAuthResult> {
    return this.getProvider(provider).resetPassword(data);
  }

  getCustomerData(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).getCustomer(data);
  }

  updateCustomerData(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).updateCustomer(data);
  }

  getBranchesForUser(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).getBranchesForUser(data);
  }

  updateBranches(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).updateBranches(data);
  }

  updateBranchName(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).updateBranchName(data);
  }

  getTemplateMealsForUser(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).getTemplateMealsForUser(data);
  }

  updateTemplateMeals(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).updateTemplateMeals(data);
  }

  getMealsForCustomerForWeek(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).getMealsForCustomerForWeek(data);
  }

  getPackagesForBranches(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).getPackagesForBranches(data);
  }

  updatePackagesForBranches(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).updatePackagesForBranches(data);
  }

  getContinuousRulesForBranches(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).getContinuousRulesForBranches(data);
  }

  updateContinuousRulesForBranches(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).updateContinuousRulesForBranches(data);
  }

  getInvoices(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).getInvoices(data);
  }

  getInvoiceForDownload(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).getInvoiceForDownload(data);
  }

  updateMealsForCustomerForWeek(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).updateMealsForCustomerForWeek(data);
  }

  getFacebookAuthUrl(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).getFacebookAuthUrl(data);
  }

  getTwitterAuthUrl(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).getTwitterAuthUrl(data);
  }

  getStatisticDataInDays(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).getStatisticDataInDays(data);
  }

  getStatisticDataInWeeks(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).getStatisticDataInWeeks(data);
  }

  getStatisticDataInMonths(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).getStatisticDataInMonths(data);
  }

  getDashboardStatistic(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).getDashboardStatistic(data);
  }

  getMealplanPDF(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).getMealplanPDF(data);
  }

  getMealplanURLs(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).getMealplanURLs(data);
  }

  saveMealPlanPrint(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).saveMealPlanPrint(data);
  }

  saveMealPlanPrintPdf(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).saveMealPlanPrintPdf(data);
  }

  updatePrintConfigByBranch(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).updatePrintConfigByBranch(data);
  }

  getPrintConfigByBranch(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).getPrintConfigByBranch(data);
  }

  getPrintConfigNamesByBranch(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).getPrintConfigNamesByBranch(data);
  }

  getPrintConfigByName(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).getPrintConfigByName(data);
  }

  saveBranchImage(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).saveBranchImage(data);
  }

  deleteBranchImage(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).deleteBranchImage(data);
  }
  getManagerMessages(provider: string, data?: any): Observable<NgxDataResult> {
    return this.getProvider(provider).getManagerMessages(data);
  }

  getProvider(provider: string): NgxAbstractAuthProvider {
    if (!this.providers[provider]) {
      throw new TypeError(`Nb auth provider '${provider}' is not registered`);
    }
    return this.injector.get(this.providers[provider].service);
  }
}
