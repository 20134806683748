import { APP_BASE_HREF } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CoreModule } from './@core/core.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ThemeModule } from './@theme/theme.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxAuthModule } from './pages/components/auth/auth.module';
import { NgxDummyAuthProvider, NgxEmailPassAuthProvider } from './@core/data/providers/index';
import { AuthGuard } from './auth-guard.service';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { NbAuthJWTInterceptor } from './@core/data/services/interceptors/jwt-interceptor';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LoadingModule, ANIMATION_TYPES } from 'ngx-loading';
import {TranslationExtention} from './pages/ui-features/TranslationExtention';
import {PrintEditorEditModeService} from './pages/components/extentions/PrintEditorEditModeService';
import { RoleGuard } from './role-guard.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

  @NgModule({
    declarations: [AppComponent],
    imports: [
      BrowserModule,
      LeafletModule.forRoot(),
      HttpClientModule,
      BrowserAnimationsModule,
      ReactiveFormsModule,
      LoadingModule.forRoot({
        animationType: ANIMATION_TYPES.circle,
        backdropBackgroundColour: 'rgba(0,0,0,0.2)',
        fullScreenBackdrop: true,
        backdropBorderRadius: '1px',
        primaryColour: '#32C2C0',
        secondaryColour: '#FF4646',
        tertiaryColour: '#32C2C0',
      }),
      AppRoutingModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      NgbModule.forRoot(),
      ThemeModule.forRoot(),
      CoreModule.forRoot(),
      NgxAuthModule.forRoot({
        providers: {
          email: {
              service: NgxEmailPassAuthProvider,
              // service: NgxDummyAuthProvider,
            config: {},
          },
        },
        forms: {},
      }),
    ],
    bootstrap: [AppComponent],
    providers: [AuthGuard,
      RoleGuard,
      PrintEditorEditModeService,
      TranslationExtention,
      {provide: APP_BASE_HREF, useValue: '/'},
      { provide: HTTP_INTERCEPTORS, useClass: NbAuthJWTInterceptor, multi: true},
    ],
  })
  export class AppModule {
}
