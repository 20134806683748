import {Allergen} from './Allergen';
import {Additives} from './Additives';

export class Meal {
  name: string;
  price: string;
  isvegetarian: boolean;
  isvegan: boolean;
  published: boolean;
  isdeleted = false;
  id: string;
  date: number;
  allergens: Allergen;
  additives: Additives;
  branchid: string;
  continuousruleid: string;
  idtemplatemeal: string;
  position: number;
  isspecialday: false;

  // nur fürs Frontend, wird nicht gespeichert
  getsAutoFocus = false;

  constructor() {}
}
