import { InjectionToken } from '@angular/core';

export interface NgxAuthOptions {
  forms?: any;
  providers?: any;
}

export interface NgxAuthProviders {
  [key: string]: any;
}

export const defaultSettings: any = {
  forms: {
    dummy: {
      redirectDelay: 500,
      provider: 'email',
      rememberMe: true,
      showMessages: {
        success: true,
        error: true,
      },
    },
    standard: {
      redirectDelay: 0,
      provider: 'email',
      rememberMe: true,
      showMessages: {
        success: true,
        error: true,
      },
    },
    login: {
      redirectDelay: 1000,
      provider: 'email',
      rememberMe: true,
      showMessages: {
        success: true,
        error: true,
      },
    },
    register: {
      redirectDelay: 5000,
      provider: 'email',
      showMessages: {
        success: true,
        error: true,
      },
      terms: true,
    },
    requestPassword: {
      redirectDelay: 5000,
      provider: 'email',
      showMessages: {
        success: true,
        error: true,
      },
    },
    resetPassword: {
      redirectDelay: 500,
      provider: 'email',
      showMessages: {
        success: true,
        error: true,
      },
    },
    logout: {
      redirectDelay: 2000,
      provider: 'email',
    },
    validation: {
      password: {
        required: true,
        minLength: 4,
        maxLength: 50,
      },
      email: {
        required: true,
      },
      emailinvoice: {
        required: false,
      },
      firstname: {
        required: true,
        minLength: 2,
        maxLength: 50,
      },
      lastname: {
        required: true,
        minLength: 2,
        maxLength: 50,
      },
      customername: {
        required: true,
        minLength: 1,
        maxLength: 50,
      },
      city: {
        required: true,
        minLength: 2,
        maxLength: 50,
      },
      zipcode: {
        required: true,
        minLength: 4,
        maxLength: 5,
      },
      street: {
        required: true,
        minLength: 2,
        maxLength: 50,
      },
      country: {
        required: true,
      },
      housenumber: {
        required: false,
        minLength: 0,
        maxLength: 10,
      },
      taxnumber: {
        required: false,
        minLength: 9,
        maxLength: 15,
      },
      iban: {
        required: false,
        minLength: 22,
        maxLength: 22,
      },
      bankowner: {
        required: false,
        minLength: 3,
        maxLength: 50,
      },
    },
  },
};

export const NGX_AUTH_OPTIONS_TOKEN = new InjectionToken<NgxAuthOptions>('Nebular Auth Options');
export const NGX_AUTH_USER_OPTIONS_TOKEN = new InjectionToken<NgxAuthOptions>('Nebular User Auth Options');
export const NGX_AUTH_PROVIDERS_TOKEN = new InjectionToken<NgxAuthProviders>('Nebular Auth Providers');
export const NGX_AUTH_TOKEN_WRAPPER_TOKEN = new InjectionToken<NgxAuthProviders>('Nebular Auth Token');
export const NGX_AUTH_INTERCEPTOR_HEADER = new InjectionToken<NgxAuthProviders>('Nebular Simple Interceptor Header');
