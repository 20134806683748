import {Component, Inject, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { NGX_AUTH_OPTIONS_TOKEN } from '..///auth.options';

import { NgxAuthResult, NgxAuthService } from '../../../../@core/data/services/auth.service';
import { getDeepFromObject } from '@nebular/auth//helpers';

@Component({
  selector: 'ngx-login',
  template: `
    <ngx-auth-block>
      <div>
        <img class="unselectable"
             src="assets/images/sRGB.png"
             height="40%" width="40%">
      </div>
      <br>
      <br>
      <h3 class="title"> {{ 'AUTH.SIGNIN' | translate }}</h3>
      <small class="form-text sub-title">{{ 'AUTH.SIGNINWELCOMETEXT' | translate }}</small>

      <form (ngSubmit)="login()" style="overflow: hidden" #form="ngForm" autocomplete="off" >

        <div *ngIf="showMessages.error && errors && errors.length > 0 && !submitted"
             class="alert alert-danger" style="color: #FF4646" role="alert">
          <div><strong>{{ 'ERROR' | translate }}!</strong></div>
          <div *ngFor="let error of errors">{{ error }}</div>
        </div>

        <div *ngIf="showMessages.success && messages && messages.length > 0 && !submitted"
             class="alert alert-success"  style="color: #32C2C0" role="alert">
          <div *ngFor="let message of messages"><strong>{{ message }}</strong></div>
        </div>

        <div class="form-group">
          <label for="input-email" class="sr-only">{{ 'AUTH.EMAILADRESS' | translate }}</label>
          <input name="email" [(ngModel)]="user.email" id="input-email" pattern=".+@.+\..+"
                 class="form-control" placeholder="{{ 'AUTH.EMAILADRESS' | translate }}" #email="ngModel"
                 [class.form-control-danger]="email.invalid && email.touched" autofocus
                 [required]="getConfigValue('forms.validation.email.required')">
          <small class="form-text error" *ngIf="email.invalid && email.touched && email?.errors?.required">
            {{ 'AUTH.NOVALIDEMAIL' | translate }}
          </small>
          <small class="form-text error"
                 *ngIf="email.invalid && email.touched && email?.errors?.pattern">
            {{ 'AUTH.NOVALIDEMAIL' | translate }}
          </small>
        </div>

        <div class="form-group">
          <label for="input-password" class="sr-only">{{ 'AUTH.PASSWORD' | translate }}</label>
          <input name="password" [(ngModel)]="user.password" type="password" id="input-password"
                 class="form-control" placeholder="{{ 'AUTH.PASSWORD' | translate }}" #password="ngModel"
                 [class.form-control-danger]="password.invalid && password.touched"
                 [required]="getConfigValue('forms.validation.password.required')"
                 [minlength]="getConfigValue('forms.validation.password.minLength')"
                 [maxlength]="getConfigValue('forms.validation.password.maxLength')">
          <small class="form-text error" *ngIf="password.invalid && password.touched && password?.errors?.required">
            {{ 'AUTH.NOVALIDPASSWORD' | translate }}
          </small>
          <small
            class="form-text error"
            *ngIf="password.invalid && password.touched && (password?.errors?.minlength || password?.errors?.maxlength)">
            {{ 'AUTH.PASSWORD' | translate }}
            {{ 'AUTH.LENGTHSHOULDBETWEEN' | translate }}
            {{ getConfigValue('forms.validation.password.minLength') }}
            {{ 'AUTH.AND' | translate }} {{ getConfigValue('forms.validation.password.maxLength') }}
            {{ 'AUTH.SIGNS' | translate }}
          </small>
        </div>

        <div class="form-group accept-group col-sm-12">
          <nb-checkbox [(ngModel)]="rememberMe" name="rememberMe">{{ 'AUTH.SAVELOGINDATA' | translate}}</nb-checkbox>
          <a class="forgot-password" routerLink="request-password">{{ 'AUTH.FORGOTPASSWORD' | translate }}</a>
        </div>

        <button [disabled]="submitted || !form.valid" class="btn btn-block btn-hero-success"
                [class.btn-pulse]="submitted">
          {{ 'AUTH.SIGNIN' | translate }}
        </button>
      </form>

      <div class="links">
        <small class="form-text">
          {{ 'AUTH.NOTREGISTRED' | translate }}
          <a style="padding-left: 5px;" routerLink="register"><strong> {{ 'AUTH.CREATENEWACCOUNT' | translate }}</strong></a>
        </small>
      </div>
      <p>
      <div class="row">
        <div class="col-md-4 col-12" style="text-align:center;">
          <a href="https://derspeiseplaner.de/Datenschutzerklaerung" target="_blank"
             style="color: #9E9E9E">Datenschutzerklärung</a>
        </div>
        <div class="col-md-4 col-12" style="text-align:center;">
          <a href="https://derspeiseplaner.de/Impressum" target="_blank"
             style="color: #9E9E9E">Impressum</a>
        </div>
        <div class="col-md-4 col-12" style="text-align:center;">
          <a href="https://derspeiseplaner.de/AGB" target="_blank"
             style="color: #9E9E9E">AGB</a>
        </div>
      </div>
    </ngx-auth-block>
  `,
})
export class NgxLoginComponent implements OnInit {
  redirectDelay = 0;
  showMessages: any = {};
  provider = '';

  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  submitted = false;
  rememberMe = false;

  constructor(protected service: NgxAuthService,
              @Inject(NGX_AUTH_OPTIONS_TOKEN) protected config = {},
              protected router: Router) {

    this.redirectDelay = this.getConfigValue('forms.login.redirectDelay');
    this.showMessages = this.getConfigValue('forms.login.showMessages');
    this.provider = this.getConfigValue('forms.login.provider');
  }

  ngOnInit() {
    if (localStorage.getItem('userAuthSpManager')) {
      this.user = {};
      this.user.email = localStorage.getItem('userAuthSpManager');

      if (this.user.email !== undefined && this.user.email !== ''
        && this.user.password !== undefined && this.user.password !== '') {
        this.submitted = true;
      }
    }
  }

  login(): void {
    if (this.rememberMe) {
      localStorage.setItem('userAuthSpManager', this.user.email);
    }

    this.errors = this.messages = [];
    this.submitted = true;

    this.service.authenticate(this.provider, this.user).subscribe((result: NgxAuthResult) => {
      this.submitted = false;
      if (result.isSuccess()) {
        this.messages = result.getMessages();
      } else {
        this.errors = result.getErrors();
        this.messages = result.getMessages();
      }
      if (result.getRedirect()) {
      setTimeout(() => {
        return this.router.navigateByUrl(result.getRedirect());
      }, this.redirectDelay);
    }
  });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.config, key, null);
  }
}
