
    <ngx-auth-block>
      <div>
        <img class="unselectable"
             src="assets/images/sRGB.png"
             height="40%" width="40%">
      </div>
      <h3 class="title">{{ 'AUTH.FORGOTPASSWORD' | translate }}</h3>
      <small class="form-text sub-title">{{ 'AUTH.ENTERYOURMAILADRESS' | translate }}</small>
      <form (ngSubmit)="requestPass()" #requestPassForm="ngForm">

        <div *ngIf="showMessages.error && errors && errors.length > 0 && !submitted"
             class="alert alert-danger" role="alert">
          <div><strong>Oh snap!</strong></div>
          <div *ngFor="let error of errors">{{ error }}</div>
        </div>
        <div *ngIf="showMessages.success && messages && messages.length > 0 && !submitted"
             class="alert alert-success" role="alert">
          <div><strong>Hooray!</strong></div>
          <div *ngFor="let message of messages">{{ message }}</div>
        </div>

        <div class="form-group">
          <label for="input-email" class="sr-only">{{ 'AUTH.ENTERYOURMAILADRESS' | translate }}</label>
          <input name="email" [(ngModel)]="user.email" id="input-email" #email="ngModel"
                 class="form-control" placeholder="{{ 'AUTH.EMAILADRESS' | translate }}" pattern=".+@.+..+"
                 [class.form-control-danger]="email.invalid && email.touched"
                 [required]="getConfigValue('forms.validation.email.required')"
                 autofocus>
          <small class="form-text error" *ngIf="email.invalid && email.touched && email.errors?.required">
            {{ 'AUTH.NOVALIDEMAIL' | translate }}
          </small>
          <small class="form-text error"
                 *ngIf="email.invalid && email.touched && email.errors?.pattern">
            {{ 'AUTH.NOVALIDEMAIL' | translate }}
          </small>
        </div>

        <button [disabled]="submitted || !requestPassForm.form.valid" class="btn btn-hero-success btn-block"
                [class.btn-pulse]="submitted">
          {{ 'AUTH.REQUESTPASSWORD' | translate }}
        </button>
      </form>

      <div class="links col-sm-12">
        <small class="form-text">
          {{ 'AUTH.ALREADYHAVEACOUNT' | translate }} 
          <a style="padding-left: 5px" routerLink="../login">
            <strong>{{ 'AUTH.SIGNIN' | translate }}</strong>
          </a>
        </small>
        <small class="form-text">
          <a routerLink="../register"><strong>{{ 'AUTH.REGISTER' | translate }}</strong></a>
        </small>
      </div>
      <p></p>
      <div class="row">
        <div class="col-sm-4 col-12" style="text-align:center;">
          <a href="https://derspeiseplaner.de/Datenschutzerklaerung" target="_blank"
             style="color: #9E9E9E">Datenschutzerklärung</a>
        </div>
        <div class="col-sm-4 col-12" style="text-align:center;">
          <a href="https://derspeiseplaner.de/Impressum" target="_blank"
             style="color: #9E9E9E">Impressum</a>
        </div>
        <div class="col-sm-4 col-12" style="text-align:center;">
          <a href="https://derspeiseplaner.de/AGB" target="_blank"
             style="color: #9E9E9E">AGB</a>
        </div>
      </div>
    </ngx-auth-block>
  