import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import 'style-loader!angular2-toaster/toaster.css';

@Component({
  selector: 'ngx-app',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent {


  constructor(translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('de');

    let newVersion = '19';
    console.log('current version:' + newVersion) ;

    if (localStorage.getItem('currentVersion')) {
      if (localStorage.getItem('currentVersion') !== newVersion) {
        localStorage.setItem('currentVersion', newVersion);
        window.location.reload();
      } else {
        console.log('newest version loaded');
      }
    } else {
      localStorage.setItem('currentVersion', newVersion);
      window.location.reload();
    }

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
  }
}
