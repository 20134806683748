import {Injector, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {HttpClientModule, HttpClient} from '@angular/common/http';

import {NbLayoutModule, NbCardModule, NbCheckboxModule} from '@nebular/theme';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {NgxAuthService} from '../../../@core/data/services/auth.service';
import {NgxDummyAuthProvider} from '../../../@core/data/providers/dummy-auth.provider';
import {NgxEmailPassAuthProvider} from '../../../@core/data/providers/email-pass-auth.provider';

import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {
  defaultSettings,
  NGX_AUTH_USER_OPTIONS_TOKEN,
  NGX_AUTH_OPTIONS_TOKEN,
  NGX_AUTH_PROVIDERS_TOKEN,
  NGX_AUTH_TOKEN_WRAPPER_TOKEN,
  NgxAuthOptions, NGX_AUTH_INTERCEPTOR_HEADER,
} from './auth.options';

import { NgxAuthComponent } from './auth.component';
import { NgxAuthJWTToken, NgxTokenService } from '../../../@core/data/services/token.service';

import { NgxAuthBlockComponent } from './auth-block/auth-block.component';
import { NgxLoginComponent } from './login/login.component';
import { NgxRegisterComponent } from './register/register.component';
import { NgxLogoutComponent } from './logout/logout.component';
import { NgxRequestPasswordComponent } from './request-password/request-password.component';
import { NgxResetPasswordComponent } from './reset-password/reset-password.component';

import { routes } from './auth.routes';
import { deepExtend } from './helpers';

export function ngxAuthServiceFactory(config: any, tokenService: NgxTokenService, injector: Injector) {
  const providers = config.providers || {};

  for (const key in providers) {
    if (providers.hasOwnProperty(key)) {
      const provider = providers[key];
      const object = injector.get(provider.service);
      object.setConfig(provider.config || {});
    }
  }

  return new NgxAuthService(tokenService, injector, providers);
}

export function nbOptionsFactory(options) {
  return deepExtend(defaultSettings, options); }

  // AoT requires an exported function for factories
  export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    NbLayoutModule,
    NbCardModule,
    NbCheckboxModule,
    RouterModule.forChild(routes),
    FormsModule,
    HttpClientModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [
    NgxAuthComponent,
    NgxAuthBlockComponent,
    NgxLoginComponent,
    NgxRegisterComponent,
    NgxRequestPasswordComponent,
    NgxResetPasswordComponent,
    NgxLogoutComponent,
  ],
  exports: [
    NgxAuthComponent,
    NgxAuthBlockComponent,
    NgxLoginComponent,
    NgxRegisterComponent,
    NgxRequestPasswordComponent,
    NgxResetPasswordComponent,
    NgxLogoutComponent,
  ],
})
export class NgxAuthModule {
  static forRoot(ngxAuthOptions?: NgxAuthOptions): ModuleWithProviders {
    return <ModuleWithProviders> {
      ngModule: NgxAuthModule,
      providers: [
        { provide: NGX_AUTH_USER_OPTIONS_TOKEN, useValue: ngxAuthOptions },
        { provide: NGX_AUTH_OPTIONS_TOKEN, useFactory: nbOptionsFactory, deps: [NGX_AUTH_USER_OPTIONS_TOKEN] },
        { provide: NGX_AUTH_PROVIDERS_TOKEN, useValue: {} },
        { provide: NGX_AUTH_TOKEN_WRAPPER_TOKEN, useClass: NgxAuthJWTToken },
        { provide: NGX_AUTH_INTERCEPTOR_HEADER, useValue: 'Authorization' },
        {
          provide: NgxAuthService,
          useFactory: ngxAuthServiceFactory,
          deps: [NGX_AUTH_OPTIONS_TOKEN, NgxTokenService, Injector],
        },
        NgxTokenService,
        NgxDummyAuthProvider,
        NgxEmailPassAuthProvider,
      ],
    };
  }
}

