import {Component, Input} from '@angular/core';
import {NbSidebarService} from '@nebular/theme';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent {

  @Input() position = 'normal';

  constructor(private sidebarService: NbSidebarService) {
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  public openManual() {
    const url = 'assets/manual/Bedienungsanleitung.pdf';
    window.open(url, '_blank');
  }
}

