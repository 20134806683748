export class Package {
  creationdate: Date = null;
  deletiondate: Date = null;
  terminationdate: Date = null;
  price: number;
  isterminated = false;
  isactive = false;
  isdaily = false;
  isweekly = false;
}
