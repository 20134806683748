import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      <b style="padding-right:20px">
        <a href="https://derspeiseplaner.de/Impressum" target="_blank">Impressum</a>
      </b>
      <b style="padding-right:20px">
        <a href="https://derspeiseplaner.de/AGB" target="_blank">AGBs</a>
      </b>
      <b style="padding-right:20px">
        <a href="https://derspeiseplaner.de/Datenschutzerklaerung" target="_blank">Datenschutzerklärung</a>
      </b>
      <b style="padding-right:20px">
        <a href="https://derspeiseplaner.de" target="_blank">Hauptseite</a>
      </b>
      <b style="padding-right:20px">
        <a href="https://derspeiseplaner.de/kontakt" target="_blank">Kontakt & Hilfe</a>
      </b>
      <b style="padding-right:20px">
        <b>Copyright © 2021</b>
      </b>
    </span>
<!--    <div class="socials">
      &lt;!&ndash;<a href="#" target="_blank" class="ion ion-social-github"></a>&ndash;&gt;
      <a href="https://derspeiseplaner.de" target="_blank">Impressum</a>
      <a href="http://derspeiseplaner.de/Impressum" target="_blank">Hauptseite</a>
      <a href="http://derspeiseplaner.de/agb" target="_blank">AGBs</a>
    </div>-->
  `,
})
export class FooterComponent {
}
