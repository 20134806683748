import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/do';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/observable/of';
import { filter } from 'rxjs/operators/filter';
import { share } from 'rxjs/operators/share';


import { NGX_AUTH_OPTIONS_TOKEN, NGX_AUTH_TOKEN_WRAPPER_TOKEN } from '../../../pages/components/auth/auth.options';
import { deepExtend, getDeepFromObject, urlBase64Decode } from '../../../pages/components/auth/helpers';

/**
 * Wrapper for simple (text) token
 */
@Injectable()
export class NgxAuthSimpleToken {

  protected token = '';

  setValue(token: string) {
    this.token = token;
  }

  /**
   * Returns the token value
   * @returns string
   */
  getValue() {
    return this.token;
  }
}

/**
 * Wrapper for JWT token with additional methods.
 */
@Injectable()
export class NgxAuthJWTToken extends NgxAuthSimpleToken {

  /**
   * Returns payload object
   * @returns any
   */
  getPayload(): any {
    const parts = this.token.split('.');

    if (parts.length !== 3) {
      throw new Error(`The token ${this.token} is not valid JWT token and must consist of three parts.`);
    }

    const decoded = urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error(`The token ${this.token} is not valid JWT token and cannot be decoded.`);
    }

    return JSON.parse(decoded);
  }

  /**
   * Returns expiration date
   * @returns Date
   */
  getTokenExpDate(): Date {
    const decoded = this.getPayload();
    if (!decoded.hasOwnProperty('exp')) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);

    return date;
  }

  isValid(): boolean {
    return !!this.getValue();
  }
}

/**
 * Nebular token service. Provides access to the stored token.
 * By default returns NgxAuthSimpleToken instance,
 * but you can inject NgxAuthJWTToken if you need additional methods for JWT token.
 *
 * @example Injecting NgxAuthJWTToken, so that NgxTokenService will now return NgxAuthJWTToken instead
 * of the default NgxAuthSimpleToken
 *
 * ```
 * // import token and service into your AppModule
 * import { NGX_AUTH_TOKEN_WRAPPER_TOKEN,  NgxAuthJWTToken} from '@nebular/auth';
 *
 * // add to a list of providers
 * providers: [
 *  // ...
 *  { provide: NGX_AUTH_TOKEN_WRAPPER_TOKEN, useClass: NgxAuthJWTToken },
 * ],
 * ```
 */
@Injectable()
export class NgxTokenService {

  protected defaultConfig: any = {
    token: {
      key: 'auth_app_token',

      getter: (): Observable<NgxAuthSimpleToken> => {
        const tokenValue = sessionStorage.getItem(this.getConfigValue('token.key'));
        this.tokenWrapper.setValue(tokenValue);
        return Observable.of(this.tokenWrapper);
      },

      setter: (token: string|NgxAuthSimpleToken): Observable<null> => {
        const raw = token instanceof NgxAuthSimpleToken ? token.getValue() : token;
        sessionStorage.setItem(this.getConfigValue('token.key'), raw);
        return Observable.of(null);
      },

      deleter: (): Observable<null> => {
        sessionStorage.removeItem(this.getConfigValue('token.key'));
        return Observable.of(null);
      },
    },
  };
  protected config: any = {};
  protected token$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(@Inject(NGX_AUTH_OPTIONS_TOKEN) protected options: any,
              @Inject(NGX_AUTH_TOKEN_WRAPPER_TOKEN) protected tokenWrapper: NgxAuthSimpleToken) {
    this.setConfig(options);

    this.get().subscribe(token => this.publishToken(token));
  }

  setConfig(config: any): void {
    this.config = deepExtend({}, this.defaultConfig, config);
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.config, key, null);
  }

  /**
   * Sets the token into the storage. This method is used by the NgxAuthService automatically.
   * @param {string} rawToken
   * @returns {Observable<any>}
   */
  set(rawToken: string): Observable<null> {
    return this.getConfigValue('token.setter')(rawToken)
      .switchMap(_ => this.get())
      .do((token: NgxAuthSimpleToken) => {
        this.publishToken(token);
      });
  }

  /**
   * Returns observable of current token
   * @returns {Observable<NgxAuthSimpleToken>}
   */
  get(): Observable<NgxAuthJWTToken> {
    return this.getConfigValue('token.getter')();
  }

  /**
   * Publishes token when it changes.
   * @returns {Observable<NgxAuthSimpleToken>}
   */
  tokenChange(): Observable<NgxAuthJWTToken> {
    return this.token$
      .pipe(
        filter(value => !!value),
        share(),
      );
  }

  /**
   * Removes the token
   * @returns {Observable<any>}
   */
  clear(): Observable<any> {
    this.publishToken(null);
    return this.getConfigValue('token.deleter')();
  }

  protected publishToken(token: NgxAuthSimpleToken): void {
    this.token$.next(token);
  }
}
