import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NGX_AUTH_OPTIONS_TOKEN } from '../auth.options';
import { getDeepFromObject } from '../helpers';
import { NgxAuthResult, NgxAuthService } from '../../../../@core/data/services/auth.service';
import { CustomerLocation, Registry } from '../../../../@core/model';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-register',
  styleUrls: ['./register.component.scss'],
  templateUrl: './register.component.html',
})
export class NgxRegisterComponent implements OnInit {

  redirectDelay = 0;
  showMessages: any = {};
  provider = '';
  terms = false;
  confirmPassword = '';
  key = 'AIzaSyC5aTCouzkyZv8i0jDG6MryfCLfPiVvLhs';

  submitted = false;
  errors: string[] = [];
  messages: string[] = [];
  registry: Registry = new Registry();
  customerLocation = new CustomerLocation();
  checkAGB = 'Bestätigen Sie die Nutzungsbedingungen!';

  ngOnInit(): void {
    this.customerLocation.country = 'Deutschland';
  }

  constructor(protected service: NgxAuthService,
              @Inject(NGX_AUTH_OPTIONS_TOKEN) protected config = {},
              protected http: HttpClient,
              protected router: Router,
              private translate: TranslateService) {

    this.redirectDelay = this.getConfigValue('forms.register.redirectDelay');
    this.showMessages = this.getConfigValue('forms.register.showMessages');
    this.provider = this.getConfigValue('forms.register.provider');

    // translate.get('INFO.CHECKAGB').subscribe((res: string) => {
    //   (this.checkAGB = res);
    // });
  }

  onCountryChange(event): void {
    this.customerLocation.country = event;
  }

  register(): void {
    if (this.terms && this.confirmPassword === this.registry.password) {
      this.errors = this.messages = [];
      this.submitted = true;
      this.registry.location = this.customerLocation;


      this.service.register(this.provider, this.registry).subscribe((result: NgxAuthResult) => {
        this.submitted = false;
        if (result.isSuccess()) {
          this.messages = result.getMessages();
        } else {
          this.errors = result.getErrors();
        }

        const redirect = result.getRedirect();
        if (redirect) {
          setTimeout(() => {
            return this.router.navigateByUrl(redirect);
          }, this.redirectDelay);
        }
      });
    } else {
      if (!this.terms) {
        window.alert(this.checkAGB);
      }
    }
  }

  // GEO Location Services
  registerWithGeoData() {
    const url = 'https://maps.googleapis.com/maps/api/geocode/json?address='
      + this.customerLocation.housenumber
      + '+' + this.customerLocation.street
      + '+' + this.customerLocation.city
      +  ',+' + this.customerLocation.country
      + '&key=' + this.key;

    this.getGoogleGeoData(url).subscribe(response => {
      if (response.status === 'OK') {
        this.customerLocation.location.lat = response.results[0]['geometry']['location'].lat;
        this.customerLocation.location.lon = response.results[0]['geometry']['location'].lng;
      }
      this.register();
    });
  }

  getGoogleGeoData(gmapsUrl: string): any {
    return this.http.get(gmapsUrl).map(response => response);
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.config, key, null);
  }
}
