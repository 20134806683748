import {Component, Inject, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { NgxAuthService, NgxAuthResult } from '../../../../@core/data/services/auth.service';
import { NGX_AUTH_OPTIONS_TOKEN } from '../auth.options';
import { getDeepFromObject } from '@nebular/auth/helpers';

@Component({
  selector: 'ngx-logout',
  template: `
    <div>{{ 'AUTH.LOGGINOUT' | translate }}</div>
  `,
})
export class NgxLogoutComponent implements OnInit {

  redirectDelay = 2000;
  provider = '';

  constructor(protected service: NgxAuthService,
              @Inject(NGX_AUTH_OPTIONS_TOKEN) protected config = {},
              protected router: Router) {
    this.provider = this.getConfigValue('forms.login.provider');
  }

  ngOnInit(): void {
    this.logout();
  }

  logout(): void {
    console.info('logout');
    this.service.logout(this.provider).subscribe((result: NgxAuthResult) => {

      const redirect = result.getRedirect();
      if (redirect) {
        setTimeout(() => {
          return this.router.navigateByUrl(redirect);
        }, this.redirectDelay);
      }
    });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.config, key, null);
  }
}
