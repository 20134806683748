<div>
  <div>
    <img class="unselectable"
         src="assets/images/sRGB.png"
         height="40%" width="40%">
  </div>
  <p></p>
  <!--<h3 class="title text-center">{{ 'AUTH.REGISTER' | translate }}</h3>-->
  <h3 class="title text-center">Kostenloser Probemonat</h3>
  <div>Schnell und einfach das smarte Planungswerkzeug und alle Onlinefunktionen der SpeiseplanApp nutzen.
    DerSpeiseplaner.de bietet Neukunden einen kostenlosen Probemonat an.
    Teilen Sie uns Ihre Kontaktdaten mit und wir erstellen Ihren persönlichen Account.
    Für Sie entstehen keinerlei Kosten.
    Nach einem Monat verfällt der Zugang automatisch.
    Wenn Sie zufrieden sind können Sie alle Funktionen natürlich auch weiterhin kostengünstig nutzen. Wir sprechen Sie vorher darauf an.</div>
  <br>
  <form (ngSubmit)="registerWithGeoData()" #form="ngForm">

    <div *ngIf="showMessages.error && errors && errors.length > 0 && !submitted"
         class="alert alert-danger" role="alert">
      <div><strong>Oh snap!</strong></div>
      <div *ngFor="let error of errors">{{ error }}</div>
    </div>
    <div *ngIf="showMessages.success && messages && messages.length > 0 && !submitted"
         class="alert alert-success" role="alert">
      <div><strong>{{ 'AUTH.HOORAY' | translate }}</strong></div>
      <div *ngFor="let message of messages">{{ message }}</div>
    </div>

    <!--Vorname-->
    <div class="row">
      <div class="form-group col-12 col-sm-6">
        <label for="input-firstname" class="sr-only">{{ 'AUTH.FIRSTNAME' | translate }}</label>
        <input name="firstName" [(ngModel)]="registry.firstname"
               id="input-firstname" #firstName="ngModel"
               class="form-control" placeholder="{{ 'AUTH.FIRSTNAME' | translate }}"
               [class.form-control-danger]="firstName.invalid && firstName.touched"
               [required]="getConfigValue('forms.validation.firstname.required')"
               [minlength]="getConfigValue('forms.validation.firstname.minLength')"
               [maxlength]="getConfigValue('forms.validation.firstname.maxLength')"
               autofocus>
        <small class="form-text error" *ngIf="firstName.invalid && firstName.touched && firstName?.errors.required">
          {{ 'AUTH.FIRSTNAME' | translate }} {{ 'AUTH.ISREQUIRED' | translate }}
        </small>
        <small
          class="form-text error"
          *ngIf="firstName.invalid && firstName.touched && (firstName?.errors.minlength || firstName?.errors.maxlength)">
          {{ 'AUTH.FIRSTNAME' | translate }}
          {{ 'AUTH.LENGTHSHOULDBETWEEN' | translate }}
          {{getConfigValue('forms.validation.firstname.minLength')}}
          {{ 'AUTH.AND' | translate }} {{getConfigValue('forms.validation.firstname.maxLength')}}
          {{ 'AUTH.SIGNS' | translate }}
        </small>
      </div>

      <!--Nachname-->
      <div class="form-group  col-12 col-sm-6">
        <label for="input-lastname" class="sr-only">{{ 'AUTH.LASTNAME' | translate }}</label>
        <input name="lastName"
               [(ngModel)]="registry.lastname"
               id="input-lastname"
               #lastName="ngModel"
               class="form-control" placeholder="{{ 'AUTH.LASTNAME' | translate }}"
               [class.form-control-danger]="lastName.invalid && lastName.touched"
               [required]="getConfigValue('forms.validation.lastname.required')"
               [minlength]="getConfigValue('forms.validation.lastname.minLength')"
               [maxlength]="getConfigValue('forms.validation.lastname.maxLength')">
        <small class="form-text error" *ngIf="lastName.invalid && lastName.touched && lastName?.errors.required">
          {{ 'AUTH.LASTNAME' | translate }} {{ 'AUTH.ISREQUIRED' | translate }}
        </small>
        <small
          class="form-text error"
          *ngIf="lastName.invalid && lastName.touched && (lastName?.errors.minlength || lastName?.errors.maxlength)">
          {{ 'AUTH.LASTNAME' | translate }}
          {{ 'AUTH.LENGTHSHOULDBETWEEN' | translate }}
          {{getConfigValue('forms.validation.lastname.minLength')}}
          {{ 'AUTH.AND' | translate }} {{getConfigValue('forms.validation.lastname.maxLength')}}
          {{ 'AUTH.SIGNS' | translate }}
        </small>
      </div>
    </div>


    <div>{{ 'AUTH.INVOICEADRESS' | translate }}</div>
    <!--Customer-->
    <div class="form-group">
      <label for="input-customername" class="sr-only">{{ 'AUTH.CUSTOMERNAME' | translate }}</label>
      <input name="customerName" [(ngModel)]="registry.customername" id="input-customername" #customerName="ngModel"
             class="form-control" placeholder="{{ 'AUTH.CUSTOMERNAME' | translate }}"
             [class.form-control-danger]="customerName.invalid && customerName.touched"
             [required]="getConfigValue('forms.validation.customername.required')"
             [minlength]="getConfigValue('forms.validation.customername.minLength')"
             [maxlength]="getConfigValue('forms.validation.customername.maxLength')">
      <small class="form-text error" *ngIf="customerName.invalid && customerName.touched && customerName?.errors.required">
        {{ 'AUTH.CUSTOMERNAME' | translate }} {{ 'AUTH.ISREQUIRED' | translate }}
      </small>
      <small
        class="form-text error"
        *ngIf="customerName.invalid && customerName.touched && (customerName?.errors.minlength || customerName?.errors.maxlength)">
        {{ 'AUTH.CUSTOMERNAME' | translate }}
        {{ 'AUTH.LENGTHSHOULDBETWEEN' | translate }}
        {{getConfigValue('forms.validation.customername.minLength')}}
        {{ 'AUTH.AND' | translate }} {{getConfigValue('forms.validation.customername.maxLength')}}
        {{ 'AUTH.SIGNS' | translate }}
      </small>
    </div>


    <div class="row">
      <!--CustomerStreet-->
      <div class="form-group col-12 col-sm-8">
        <label for="input-customerstreet" class="sr-only">{{ 'AUTH.STREET' | translate }}</label>
        <input name="customerStreet" [(ngModel)]="customerLocation.street" id="input-customerstreet" #customerStreet="ngModel"
               class="form-control" placeholder="{{ 'AUTH.STREET' | translate }}"
               [class.form-control-danger]="customerStreet.invalid && customerStreet.touched"
               [required]="getConfigValue('forms.validation.street.required')"
               [minlength]="getConfigValue('forms.validation.street.minLength')"
               [maxlength]="getConfigValue('forms.validation.street.maxLength')">
        <small class="form-text error" *ngIf="customerStreet.invalid && customerStreet.touched && customerStreet?.errors.required">
          {{ 'AUTH.STREET' | translate }} {{ 'AUTH.ISREQUIRED' | translate }}
        </small>
        <small
          class="form-text error"
          *ngIf="customerStreet.invalid && customerStreet.touched && (customerStreet?.errors.minlength || customerStreet?.errors.maxlength)">
          {{ 'AUTH.STREET' | translate }}
          {{ 'AUTH.LENGTHSHOULDBETWEEN' | translate }}
          {{getConfigValue('forms.validation.street.minLength')}}
          {{ 'AUTH.AND' | translate }} {{getConfigValue('forms.validation.street.maxLength')}}
          {{ 'AUTH.SIGNS' | translate }}
        </small>
      </div>

      <!--CustomerHouseNumber-->
      <div class="form-group col-12 col-sm-4">
        <label for="input-customerHouseNumber" class="sr-only">{{ 'AUTH.HOUSENUMBER' | translate }}</label>
        <input name="customerHouseNumber" [(ngModel)]="customerLocation.housenumber" id="input-customerHouseNumber" #customerHouseNumber="ngModel"
               class="form-control" placeholder="{{ 'AUTH.HOUSENUMBER' | translate }}"
               [class.form-control-danger]="customerStreet.invalid && customerStreet.touched"
               [required]="getConfigValue('forms.validation.housenumber.required')"
               [minlength]="getConfigValue('forms.validation.housenumber.minLength')"
               [maxlength]="getConfigValue('forms.validation.housenumber.maxLength')">
        <small class="form-text error" *ngIf="customerHouseNumber.invalid && customerHouseNumber.touched && customerHouseNumber?.errors.required">
          {{ 'AUTH.HOUSENUMBER' | translate }} {{ 'AUTH.ISREQUIRED' | translate }}
        </small>
        <small
          class="form-text error"
          *ngIf="customerHouseNumber.invalid && customerHouseNumber.touched && (customerHouseNumber?.errors.minlength || customerHouseNumber?.errors.maxlength)">
          {{ 'AUTH.STREET' | translate }}
          {{ 'AUTH.LENGTHSHOULDBETWEEN' | translate }}
          {{getConfigValue('forms.validation.housenumber.minLength')}}
          {{ 'AUTH.AND' | translate }} {{getConfigValue('forms.validation.housenumber.maxLength')}}
          {{ 'AUTH.SIGNS' | translate }}
        </small>
      </div>
    </div>


    <div class="row">
      <!--CustomerZipCode-->
      <div class="form-group col-12 col-sm-4">
        <label for="input-customerZipCode" class="sr-only">{{ 'AUTH.ZIPCODE' | translate }}</label>
        <input name="customerZipCode" [(ngModel)]="customerLocation.zipcode" id="input-customerZipCode" #customerZipCode="ngModel"
               class="form-control" placeholder="{{ 'AUTH.ZIPCODE' | translate }}"
               [class.form-control-danger]="customerZipCode.invalid && customerZipCode.touched"
               [required]="getConfigValue('forms.validation.zipcode.required')"
               [minlength]="getConfigValue('forms.validation.zipcode.minLength')"
               [maxlength]="getConfigValue('forms.validation.zipcode.maxLength')">
        <small class="form-text error" *ngIf="customerZipCode.invalid && customerZipCode.touched && customerZipCode?.errors.required">
          {{ 'AUTH.ZIPCODE' | translate }} {{ 'AUTH.ISREQUIRED' | translate }}
        </small>
        <small
          class="form-text error"
          *ngIf="customerZipCode.invalid && customerZipCode.touched && (customerZipCode?.errors.minlength || customerZipCode?.errors?.maxlength)">
          {{ 'AUTH.ZIPCODE' | translate }}
          {{ 'AUTH.LENGTHSHOULD' | translate }}
          {{getConfigValue('forms.validation.zipcode.maxLength')}}
          {{ 'AUTH.SIGNS' | translate }}
        </small>
      </div>

      <!--CustomerCity-->
      <div class="form-group col-12 col-sm-8">
        <label for="input-customerCity" class="sr-only">{{ 'AUTH.CITY' | translate }}</label>
        <input name="customerCity" [(ngModel)]="customerLocation.city" id="input-customerCity" #customerCity="ngModel"
               class="form-control" placeholder="{{ 'AUTH.CITY' | translate }}"
               [class.form-control-danger]="customerCity.invalid && customerCity.touched"
               [required]="getConfigValue('forms.validation.city.required')"
               [minlength]="getConfigValue('forms.validation.city.minLength')"
               [maxlength]="getConfigValue('forms.validation.city.maxLength')">
        <small class="form-text error" *ngIf="customerCity.invalid && customerCity.touched && customerCity?.errors?.required">
          {{ 'AUTH.CITY' | translate }} {{ 'AUTH.ISREQUIRED' | translate }}
        </small>
        <small
          class="form-text error"
          *ngIf="customerCity.invalid && customerCity.touched && (customerCity?.errors?.minlength || customerCity?.errors?.maxlength)">
          {{ 'AUTH.STREET' | translate }}
          {{ 'AUTH.LENGTHSHOULDBETWEEN' | translate }}
          {{getConfigValue('forms.validation.city.minLength')}}
          {{ 'AUTH.AND' | translate }} {{getConfigValue('forms.validation.city.maxLength')}}
          {{ 'AUTH.SIGNS' | translate }}
        </small>
      </div>
    </div>

    <div class="form-group">
      <select class="form-select select-nb" [required]="getConfigValue('forms.validation.city.required')" (change)="onCountryChange($event.target.value)" #country name="country"  aria-label="Default select example">
        <!-- <option value="" disabled selected>Wählen Sie Ihr Land</option> -->
        <option value="Deutschland">Deutschland</option>
        <option value="Oestereich">Östereich</option>
        <option value="Schweiz">Schweiz</option>
      </select>
      <small class="form-text error" *ngIf="country.invalid && country.touched && country?.errors?.required">
        Wählen Sie bitte Ihr Land aus
      </small>
    </div>

    <!--CustomerTaxNumber-->
    <div class="form-group">
      <label for="input-taxNumber" class="sr-only">{{ 'AUTH.TAXNUMBER' | translate }}</label>
      <input name="taxNumber" [(ngModel)]="registry.taxnumber" id="input-taxNumber" #taxNumber="ngModel"
             class="form-control" placeholder="{{ 'AUTH.TAXNUMBER' | translate }}"
             [class.form-control-danger]="taxNumber.invalid && taxNumber.touched"
             [required]="getConfigValue('forms.validation.taxnumber.required')"
             [minlength]="getConfigValue('forms.validation.taxnumber.minLength')"
             [maxlength]="getConfigValue('forms.validation.taxnumber.maxLength')">
      <small class="form-text error" *ngIf="taxNumber.invalid && taxNumber.touched && taxNumber?.errors?.required">
        {{ 'AUTH.TAXNUMBER' | translate }} {{ 'AUTH.ISREQUIRED' | translate }}
      </small>
      <small
        class="form-text error"
        *ngIf="taxNumber.invalid && taxNumber.touched && (taxNumber?.errors?.minlength || taxNumber?.errors?.maxlength)">
        {{ 'AUTH.TAXNUMBER' | translate }}
        {{ 'AUTH.LENGTHSHOULDBETWEEN' | translate }}
        {{getConfigValue('forms.validation.taxnumber.minLength')}}
        {{ 'AUTH.AND' | translate }} {{getConfigValue('forms.validation.taxnumber.maxLength')}}
        {{ 'AUTH.SIGNS' | translate }}
      </small>
    </div>

    <!--email-->
    <div class="form-group">
      <label for="input-email" class="sr-only">{{ 'AUTH.EMAILADRESS' | translate }}</label>
      <input name="email" [(ngModel)]="registry.email" id="input-email" #email="ngModel"
             class="form-control" placeholder="{{ 'AUTH.EMAILADRESS' | translate }}" pattern=".+@.+..+"
             [class.form-control-danger]="email.invalid && email.touched"
             [required]="getConfigValue('forms.validation.email.required')">
      <small class="form-text error" *ngIf="email.invalid && email.touched && email?.errors?.required">
        {{ 'AUTH.NOVALIDEMAIL' | translate }}
      </small>
      <small class="form-text error"
             *ngIf="email.invalid && email.touched && email?.errors?.pattern">
        {{ 'AUTH.NOVALIDEMAIL' | translate }}
      </small>
    </div>

    <!--password-->
    <div class="form-group">
      <label for="input-password" class="sr-only">{{ 'AUTH.PASSWORD' | translate }}</label>
      <input name="password" [(ngModel)]="registry.password" type="password" id="input-password"
             class="form-control" placeholder="{{ 'AUTH.PASSWORD' | translate }}" #password="ngModel"
             [class.form-control-danger]="password.invalid && password.touched"
             [required]="getConfigValue('forms.validation.password.required')"
             [minlength]="getConfigValue('forms.validation.password.minLength')"
             [maxlength]="getConfigValue('forms.validation.password.maxLength')">
      <small class="form-text error" *ngIf="password.invalid && password.touched && password?.errors?.required">
        {{ 'AUTH.NOVALIDPASSWORD' | translate }}
      </small>
      <small
        class="form-text error"
        *ngIf="password.invalid && password.touched && (password?.errors?.minlength || password?.errors?.maxlength)">
        {{ 'AUTH.PASSWORD' | translate }}
        {{ 'AUTH.LENGTHSHOULDBETWEEN' | translate }}
        from {{ getConfigValue('forms.validation.password.minLength') }}
        {{ 'AUTH.AND' | translate }} {{ getConfigValue('forms.validation.password.maxLength') }}
        {{ 'AUTH.SIGNS' | translate }}
      </small>
    </div>

    <!--confirmPassword-->
    <div class="form-group">
      <label for="input-re-password" class="sr-only">{{ 'AUTH.CONFIRMPASSWORD' | translate }}</label>
      <input
        name="rePass" [(ngModel)]="confirmPassword" type="password" id="input-re-password"
        class="form-control" placeholder="{{ 'AUTH.CONFIRMPASSWORD' | translate }}" #rePass="ngModel"
        [class.form-control-danger]="(rePass.invalid || password.value != rePass.value) && rePass.touched"
        [required]="getConfigValue('forms.validation.password.required')">
      <small class="form-text error"
             *ngIf="rePass.invalid && rePass.touched && rePass?.errors?.required">
        {{ 'AUTH.PASSWORDCONFIRMATIONREQUIRED' | translate }} </small>
      <small
        class="form-text error"
        *ngIf="rePass.touched && password.value != rePass.value && !rePass.errors?.required">
        {{ 'AUTH.PASSWORDDONTMATCH' | translate }}
      </small>
    </div>

    <!--register terms-->

    <div class="form-group accept-group col-sm-12" *ngIf="getConfigValue('forms.register.terms')">
      <nb-checkbox #termsconditions="ngModel"
                   name="termscondition"
                   [(ngModel)]="terms"
                   [required]="getConfigValue('forms.register.terms')">
        {{ 'AUTH.AGREE' | translate }}
        <a href="https://derspeiseplaner.de/AGB"
           target="_blank"><strong>{{ 'AUTH.TERMSCONDITIONS' | translate }}</strong></a>
        {{ 'AUTH.ZU' | translate }}
      </nb-checkbox>
    </div>

    <!--buttonRegister-->
<!--    <button [disabled]="submitted || !form.valid" class="btn btn-block btn-hero-success"
            [class.btn-pulse]="submitted">
      {{ 'AUTH.REGISTERPAYMENT' | translate }}
    </button>-->
    <button [disabled]="submitted || !form.valid" class="btn btn-block btn-hero-success"
            [class.btn-pulse]="submitted">
      kostenlos registrieren
    </button>
  </form>

  <div class="links" style="padding-top: 10px">
    <small class="form-text">
      {{ 'AUTH.ALREADYHAVEACOUNT' | translate }} <a routerLink="../login"><strong>{{ 'AUTH.SIGNIN' | translate }}</strong></a>
    </small>
  </div>
  <p></p>
  <div class="row">
    <div class="col-md-3 col-sm-6 col-12" style="text-align:center;">
      <a href="https://derspeiseplaner.de/preise" target="_blank"
         style="color: #9E9E9E">Preise</a>
    </div>

    <div class="col-md-3 col-sm-6 col-12" style="text-align:center;">
      <a href="https://derspeiseplaner.de/Impressum" target="_blank"
         style="color: #9E9E9E">Impressum</a>
    </div>

    <div class="col-md-3 col-sm-6 col-12" style="text-align:center;">
      <a href="https://derspeiseplaner.de/Datenschutzerklaerung" target="_blank"
         style="color: #9E9E9E">Datenschutzerklärung</a>
    </div>

    <div class="col-md-3 col-sm-6 col-12" style="text-align:center;">
      <a href="https://derspeiseplaner.de/AGB" target="_blank"
         style="color: #9E9E9E">AGB</a>
    </div>
</div>
</div>
