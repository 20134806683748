export class Allergen {
  gluten = false;
  weizen = false;
  roggen = false;
  gerste = false;
  hafer = false;
  dinkel = false;
  kamut = false;
  mandel = false;
  haselnuss = false;
  walnuss = false;
  pekannuss = false;
  pistazie = false;
  macadamianuss = false;
  lactose = false;
  milk = false;
  egg = false;
  soy = false;
  peanut = false;
  crustaceans = false;
  molluscs = false; // seafood
  nuts = false;
  fish = false;
  lupine = false;
  sesame = false;
  mustard = false;
  sulfurdioxide = false;
  celery = false;
  comment = '';
}
