import { HttpResponse } from '@angular/common/http';
import { deepExtend, getDeepFromObject } from '../../../pages/components/auth/helpers';

export abstract class NgxAbstractProvider {

  protected defaultConfig: any = {};
  protected config: any = {};

  setConfig(config: any): void {
    this.config = deepExtend({}, this.defaultConfig, config);
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.config, key, null);
  }

  protected createFailResponse(data?: any): HttpResponse<Object> {
    return new HttpResponse<Object>({ body: {}, status: 401 });
  }

  protected createSuccessResponse(data?: any): HttpResponse<Object> {
    return new HttpResponse<Object>({ body: {}, status: 200 });
  }

  protected getJsonSafe(res: HttpResponse<Object>): any {
    return res.body;
  }
}
