import {Component, Inject} from '@angular/core';
import {Router } from '@angular/router';
import {NGX_AUTH_OPTIONS_TOKEN} from '../auth.options';
import {getDeepFromObject} from '../..//auth//helpers';
import {NgxAuthResult, NgxAuthService} from '../../../../@core/data/services/auth.service';
import {User} from '../../../../@core/model/User';

@Component({
  selector: 'ngx-reset-password-page',
  styleUrls: ['./reset-password.component.scss'],
  template: `
    <ngx-auth-block>
      <div>
        <img class="unselectable"
             src="assets/images/sRGB.png"
             height="40%" width="40%">
      </div>
      <h3 class="title">{{ 'AUTH.CHANGEPASSWORD' | translate }}</h3>
      <small class="form-text sub-title">{{ 'AUTH.ENTERNEWPASSWORD' | translate }}</small>
      <form (ngSubmit)="resetPass()" #resetPassForm="ngForm">

        <div *ngIf="errors && errors.length > 0 && !submitted" class="alert alert-danger" role="alert">
          <div><strong>Oh snap!</strong></div>
          <div *ngFor="let error of errors">{{ error }}</div>
        </div>
        <div *ngIf="messages && messages.length > 0 && !submitted" class="alert alert-success" role="alert">
          <div><strong>{{ 'AUTH.HOORAY' | translate }}</strong></div>
          <div *ngFor="let message of messages">{{ message }}</div>
        </div>

        <div class="form-group">
          <label for="input-password" class="sr-only">{{ 'AUTH.NEWPASSWORD' | translate }}</label>
          <input name="password" [(ngModel)]="user.password" type="password" id="input-password"
                 class="form-control form-control-lg first" placeholder="New Password" #password="ngModel"
                 [class.form-control-danger]="password.invalid && password.touched"
                 [required]="getConfigValue('forms.validation.password.required')"
                 [minlength]="getConfigValue('forms.validation.password.minLength')"
                 [maxlength]="getConfigValue('forms.validation.password.maxLength')"
                 autofocus>
          <small class="form-text error" *ngIf="password.invalid && password.touched && password?.errors?.required">
            {{ 'AUTH.PASSWORDREQUIRED' | translate }}
          </small>
          <small
            class="form-text error"
            *ngIf="password.invalid && password.touched && (password?.errors?.minlength || password?.errors?.maxlength)">
            {{ 'AUTH.PASSWORD' | translate }}
            {{ 'AUTH.LENGTHSHOULDBETWEEN' | translate }}
            from {{ getConfigValue('forms.validation.password.minLength') }}
            {{ 'AUTH.AND' | translate }} {{ getConfigValue('forms.validation.password.maxLength') }}
            {{ 'AUTH.SIGNS' | translate }}
          </small>
        </div>

        <div class="form-group">
          <label for="input-re-password" class="sr-only">{{ 'AUTH.CONFIRMPASSWORD' | translate }}</label>
          <input
            name="rePass" [(ngModel)]="confirmPassword" type="password" id="input-re-password"
            class="form-control form-control-lg last" placeholder="{{ 'AUTH.CONFIRMPASSWORD' | translate }}" #rePass="ngModel"
            [class.form-control-danger]="(rePass.invalid || password.value != rePass.value) && rePass.touched"
            [required]="getConfigValue('forms.validation.password.required')">
          <small class="form-text error"
                 *ngIf="rePass.invalid && rePass.touched && rePass?.errors?.required">
            {{ 'AUTH.PASSWORDCONFIRMATIONREQUIRED' | translate }}
          </small>
          <small
            class="form-text error"
            *ngIf="rePass.touched && password.value != rePass.value && !rePass.errors?.required">
            {{ 'AUTH.PASSWORDDONTMATCH' | translate }}
          </small>
        </div>

        <button [disabled]="submitted || !resetPassForm.form.valid" class="btn btn-hero-success btn-block"
                [class.btn-pulse]="submitted">
          {{ 'AUTH.CHANGEPASSWORD' | translate }}
        </button>
      </form>

      <div class="links col-sm-12">
        <small class="form-text">
          {{ 'AUTH.ALREADYHAVEACOUNT' | translate }} <a routerLink="../login"><strong>{{ 'AUTH.SIGNIN' | translate }}</strong></a>
        </small>
        <small class="form-text" style="padding-top: 10px">
          <a routerLink="../register"><strong>{{ 'AUTH.SIGNUP' | translate }}</strong></a>
        </small>
      </div>
      <div style="padding-top: 10px">
        <b style="padding-right:20px">
          <a href="https://derspeiseplaner.de/Datenschutzerklaerung" target="_blank" style="color: #9E9E9E">Datenschutzerklärung</a>
        </b>
        <b style="padding-right:20px">
          <a href="https://derspeiseplaner.de/Impressum" target="_blank" style="color: #9E9E9E">Impressum</a>
        </b>
      </div>
    </ngx-auth-block>
  `,
})
export class NgxResetPasswordComponent {

  redirectDelay = 0;
  showMessages: any = {};
  provider = '';
  submitted = false;
  errors: string[] = [];
  messages: string[] = [];
  user: User;
  confirmPassword = '';

  constructor(protected service: NgxAuthService,
              @Inject(NGX_AUTH_OPTIONS_TOKEN) protected config = {},
              protected router: Router) {

    this.user = new User();
    this.redirectDelay = this.getConfigValue('forms.resetPassword.redirectDelay');
    this.showMessages = this.getConfigValue('forms.resetPassword.showMessages');
    this.provider = this.getConfigValue('forms.resetPassword.provider');
  }

  resetPass(): void {
    if (this.confirmPassword === this.user.password) {

      this.errors = this.messages = [];
      this.submitted = true;

      this.service.resetPassword(this.provider, this.user).subscribe((result: NgxAuthResult) => {
        this.submitted = false;
        if (result.isSuccess()) {
          this.messages = result.getMessages();
        } else {
          this.errors = result.getErrors();
        }

        const redirect = result.getRedirect();
        if (redirect) {
          setTimeout(() => {
            return this.router.navigateByUrl(redirect);
          }, this.redirectDelay);
        }
      });
    }
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.config, key, null);
  }
}
