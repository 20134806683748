import {Allergen} from './Allergen';
import {Additives} from './Additives';

export class TemplateMeal {
  name: string;
  price: string;
  isvegan = false;
  isvegetarian = false;
  id: string;
  isdeleted: boolean;
  allergens: Allergen;
  additives: Additives;
  isspecialday: false;
  // nur fürs Frontend, wird nicht gespeichert
  getsAutoFocus = false;
}
