import {Observable} from 'rxjs/Observable';
import {NgxAuthResult, NgxDataResult} from '../services';
import {NgxAbstractProvider} from './abstract-provider';

export abstract class NgxAbstractAuthProvider extends NgxAbstractProvider {

  abstract authenticate(data?: any): Observable<NgxAuthResult>;

  abstract register(data?: any): Observable<NgxAuthResult>;

  abstract requestPassword(data?: any): Observable<NgxAuthResult>;

  abstract resetPassword(data?: any): Observable<NgxAuthResult>;

  abstract logout(): Observable<NgxAuthResult>;

  abstract getCustomer(data?: any): Observable<NgxDataResult>;

  abstract updateCustomer(data?: any): Observable<NgxDataResult>;

  abstract updateBranches(data?: any): Observable<NgxDataResult>;

  abstract updateBranchName(data?: any): Observable<NgxDataResult>;

  abstract getBranchesForUser(data?: any): Observable<NgxDataResult>;

  abstract getTemplateMealsForUser(data?: any): Observable<NgxDataResult>;

  abstract updateTemplateMeals(data?: any): Observable<NgxDataResult>;

  abstract getMealsForCustomerForWeek(data?: any): Observable<NgxDataResult>;

  abstract updateMealsForCustomerForWeek(data?: any): Observable<NgxDataResult>;

  abstract getPackagesForBranches(data?: any): Observable<NgxDataResult>;

  abstract updateContinuousRulesForBranches(data?: any): Observable<NgxDataResult>;

  abstract getContinuousRulesForBranches(data?: any): Observable<NgxDataResult>;

  abstract updatePackagesForBranches(data?: any): Observable<NgxDataResult>;

  abstract getInvoices(data?: any): Observable<NgxDataResult>;

  abstract getInvoiceForDownload(data?: any): Observable<NgxDataResult>;

  abstract getFacebookAuthUrl(data?: any): Observable<NgxDataResult>;

  abstract getTwitterAuthUrl(data?: any): Observable<NgxDataResult>;

  abstract getMealplanPDF(data?: any): Observable<NgxDataResult>;

  abstract getMealplanURLs(data?: any): Observable<NgxDataResult>;

  abstract getStatisticDataInDays(data?: any): Observable<NgxDataResult>;

  abstract getStatisticDataInWeeks(data?: any): Observable<NgxDataResult>;

  abstract getStatisticDataInMonths(data?: any): Observable<NgxDataResult>;

  abstract getDashboardStatistic(data?: any): Observable<NgxDataResult>;

  abstract saveMealPlanPrint(data?: any): Observable<NgxDataResult>;

  abstract updatePrintConfigByBranch(data?: any): Observable<NgxDataResult>;

  abstract getPrintConfigByBranch(data?: any): Observable<NgxDataResult>;

  abstract saveMealPlanPrintPdf(data?: any): Observable<NgxDataResult>;

  abstract saveBranchImage(data?: any): Observable<NgxDataResult>;

  abstract deleteBranchImage(data?: any): Observable<NgxDataResult>;

  abstract getManagerMessages(data?: any): Observable<NgxDataResult>;

  abstract getPrintConfigNamesByBranch(data?: any): Observable<NgxDataResult>;

  abstract getPrintConfigByName(data?: any): Observable<NgxDataResult>;
}
