import { CustomerLocation } from './CustomerLocation';

export class Registry {
  firstname: string;
  lastname: string;
  customername: string;
  email: string;
  taxnumber: string;
  password: string;
  website: string;
  location: CustomerLocation;
}
