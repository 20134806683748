import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import { tap } from 'rxjs/operators';
import {NgxAuthService} from './@core/data/services';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(private authService: NgxAuthService,
              private router: Router) {
  }

  canActivate() {
    console.log(!this.authService.getIsBranchUser());
    return !this.authService.getIsBranchUser();
  }
}
