import {Meal} from './Meal';

export class TemplateMealModal {
  meal: Meal;

  monday = false;
  tuesday = false;
  wednesday = false;
  thursday = false;
  friday = false;
  saturday = false;
  sunday = false;
}
