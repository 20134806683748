import { CustomerLocation } from './CustomerLocation';
import { Package } from './Package';
import { Image } from './Image';

export class Branch {
  id: string;
  name: string;
  isdeleted = false;
  customerid: string;
  creationdate: string;
  url: string;
  tags: string;

  autopublish = true;
  manageAllergensSelf = false;
  autoSaveMealsAsTemplate = false;
  contactServiceActive = false;
  takeawayServiceActive = false;
  deliveryServiceActive = false;
  order = "";

  description: string;
  email: string;
  website: string;
  opentime: string;
  locations: Array<CustomerLocation>;
  images: Array<Image>;
  changinglocations: boolean;
  phone: string;

  deletiondate: Date = null;
  terminationdate: Date = null;
  price: number;
  isterminated: boolean;
  isactive: boolean;

  facebookconfig: Package;
  twitterconfig: Package;
  websiteconfig: Package;

  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  // nur fürs Frontend, wird nicht gespeichert
  getsAutoFocus = false;
  interntags : { [key: string]: any } = {};
}
