import {EnumContinuousType} from './index';

export class ContinuousRule {
  continuoustype: EnumContinuousType;
  branchid: string;
  templatemealid: string;
  monday = false;
  tuesday = false;
  wednesday = false;
  thursday = false;
  friday = false;
  saturday = false;
  sunday = false;
  id: string;
  isdeleted: boolean;
  position: number;
}
