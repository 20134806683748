export class Additives {
  antioxidant = false;
  blackened = false;
  caffeine = false;
  comment = '';
  dye = false;
  flavorenhancer = false;
  genetically = false;
  phosphate = false;
  preservative = false;
  quinine = false;
  sulphurised = false;
  sweeteneraspatame = false;
  sweetenercyclamate = false;
  sweetenersaccharin = false;
  sweeteneracesulfame = false;
  waxed = false;
  nitrite = false;
}
