import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {NgxAuthResult, NgxDataResult} from '../services/auth.service';
import {NgxAbstractAuthProvider} from './abstract-auth.provider';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import {getDeepFromObject} from '../../../pages/components/auth/helpers';

export interface NbDummyAuthProviderConfig {
  delay?: number;
  alwaysFail?: boolean;
  token?: {
    key?: string;
    getter?: Function;
  };
}

@Injectable()
export class NgxDummyAuthProvider extends NgxAbstractAuthProvider {

  baseUrl = 'http://localhost:8080/SpManager';

  constructor(private _http: HttpClient) {
    super();
  }

  protected defaultConfig: NbDummyAuthProviderConfig = {
    delay: 500,
    alwaysFail: false,
    token: {
      key: 'token.token',
      getter: (module: string, res: HttpResponse<Object>) => getDeepFromObject(res.body, this.getConfigValue('token.key')),
    },
  };

  authenticate(data?: any): Observable<NgxAuthResult> {
    return this.createLoginResult(data)
      .delay(this.getConfigValue('delay'));
  }
  protected createLoginResult(data?: any): Observable<NgxAuthResult> {

    return this._http.get(this.baseUrl + '/login.Json', {
      headers: new HttpHeaders().set('Access-Control-Allow-Origin', 'my-auth-token'),
    },
    )
      .map((res) => {
        return new NgxAuthResult(
          true,
          new HttpResponse<Object>({ body: res, status: 200,
            headers: new HttpHeaders().set('Access-Control-Allow-Origin', 'my-auth-token') }),
          'pages',
          [],
          [],
          this.getConfigValue('token.getter')('login', new HttpResponse<Object>({ body: res, status: 200 })));
      })
      .catch((res) => {
        let errors = [];
        // console.info(method, url, { body: data, observe: 'response' });
        if (res instanceof HttpErrorResponse) {
          errors = this.getConfigValue('errors.getter')('login', res);
        } else {
          errors.push('Something went wrong.');
        }

        return Observable.of(
          new NgxAuthResult(
            false,
            res,
            this.getConfigValue('login.redirect.failure'),
            errors,
          ));
      });
  }

  register(data?: any): Observable<NgxAuthResult> {
    return Observable.of(this.createDummyAuthResult(data))
      .delay(this.getConfigValue('delay'));
  }

  requestPassword(data?: any): Observable<NgxAuthResult> {
    return Observable.of(this.createDummyAuthResult(data))
      .delay(this.getConfigValue('delay'));
  }

  resetPassword(data?: any): Observable<NgxAuthResult> {
    return Observable.of(this.createDummyAuthResult(data))
      .delay(this.getConfigValue('delay'));
  }


  logout(data?: any): Observable<NgxAuthResult> {
    return Observable.of(this.createLogoutResult(data))
      .delay(this.getConfigValue('delay'));
  }

  protected createLogoutResult(data?: any): NgxAuthResult {
    return new NgxAuthResult(true, this.createSuccessResponse(data), '/', ['Successfully logged in.']);
  }

  // holt die Customer Daten
  getCustomer(data?: any): Observable<NgxDataResult> {
    return this.createCustomerResult(data)
      .delay(this.getConfigValue('delay'));
  }
  // schreibt die Customer Daten
  updateCustomer(data?: any): Observable<NgxDataResult> {
    return this.createCustomerUpdateResult(data)
      .delay(this.getConfigValue('delay'));
  }
  protected createCustomerResult(data?: any): Observable<NgxDataResult> {
    return this._http.get(this.baseUrl + '/getCustomer.Json')
      .map((response) => {
        return new NgxDataResult(
          true,
          new HttpResponse<Object>({ body: response, status: 200 }),
          '',
          []);
      });
  }

  protected createCustomerUpdateResult(data?: any): Observable<NgxDataResult> {
    return this._http.get(this.baseUrl + '/getCustomer.Json')
      .map((response) => {
        return new NgxDataResult(
          true,
          new HttpResponse<Object>({ body: response, status: 200 }),
          '',
          []);
      });
  }

  getBranchesForUser(data?: any): Observable<NgxDataResult> {
    return this.createBranchesForUserResult(data)
      .delay(this.getConfigValue('delay'));
  }

  // :ToDo
  // schreibt die Branches Daten
  updateBranches(data?: any): Observable<NgxDataResult> {
    return this.updateBranchesForUserResult(data)
      .delay(this.getConfigValue('delay'));
  }
  protected updateBranchesForUserResult(data?: any): Observable<NgxDataResult> {
    return this._http.get(this.baseUrl + '/branchesForUser.Json')
      .map((response) => {
        return new NgxDataResult(
          true,
          new HttpResponse<Object>({body: response, status: 200}),
          '',
          []);
      });
  }

  // schreibt die Branches Daten
  updateBranchName(data?: any): Observable<NgxDataResult> {
    return this.updateBranchesForUserResult(data)
      .delay(this.getConfigValue('delay'));
  }

  protected createBranchesForUserResult(data?: any): Observable<NgxDataResult> {
    return this._http.get(this.baseUrl + '/branchesForUser.Json')
      .map((response) => {
        return new NgxDataResult(
          true,
          new HttpResponse<Object>({body: response, status: 200}),
          '',
          []);
      });
  }

  getTemplateMealsForUser(data?: any): Observable<NgxDataResult> {
    return this.createTemplateMealsForUserResult(data)
      .delay(this.getConfigValue('delay'));
  }
  protected createTemplateMealsForUserResult(data?: any): Observable<NgxDataResult> {
    return this._http.get(this.baseUrl + '/getTemplateMealsForUser.Json')
      .map((response) => {
        return new NgxDataResult(
          true,
          new HttpResponse<Object>({ body: response, status: 200 }),
          '',
          []);
      });
  }

  // :ToDo
  // schreibt die TemplateMeals
  updateTemplateMeals(data?: any): Observable<NgxDataResult> {
    return this.createTemplateMealsForUserResult(data)
      .delay(this.getConfigValue('delay'));
  }

  getMealsForCustomerForWeek(data?: any): Observable<NgxDataResult> {
    return this.createMealsForBranchForWeekResult(data)
      .delay(this.getConfigValue('delay'));
  }

  updateMealsForCustomerForWeek(data?: any): Observable<NgxDataResult> {
    return this.updateMealsForBranchForWeekResult(data)
      .delay(this.getConfigValue('delay'));
  }

  protected updateMealsForBranchForWeekResult(data?: any): Observable<NgxDataResult> {
    return this._http.get(this.baseUrl + '/mealsForBranchForWeek0.Json')
      .map((response) => {
        return new NgxDataResult(
          true,
          new HttpResponse<Object>({ body: response, status: 200 }),
          '',
          []);
      });
  }

  protected createMealsForBranchForWeekResult(data?: any): Observable<NgxDataResult> {
    const a = data['startdate'];
    const b = this.getMonday();

    if (a.valueOf() === b.valueOf()) {
      return this._http.get(this.baseUrl + '/mealsForBranchForWeek0.Json')
        .map((response) => {
          return new NgxDataResult(
            true,
            new HttpResponse<Object>({ body: response, status: 200 }),
            '',
            []);
        });
    } else {
      return this._http.get(this.baseUrl + '/mealsForBranchForWeek0.Json')
        .map((response) => {
          return new NgxDataResult(
            true,
            new HttpResponse<Object>({ body: response, status: 200 }),
            '',
            []);
        });
    }
  }

  getPackagesForBranches(data?: any): Observable<NgxDataResult> {
    return this.createPackagesForBranchesDummyResult(data)
      .delay(this.getConfigValue('delay'));
  }

  updatePackagesForBranches(data?: any): Observable<NgxDataResult> {
    return this.updatePackagesForBranchesDummyResult(data)
      .delay(this.getConfigValue('delay'));
  }

  protected createPackagesForBranchesDummyResult(data?: any): Observable<NgxDataResult> {
    return this._http.get(this.baseUrl + '/getPackagesForBranches.Json')
      .map((response) => {
        return new NgxDataResult(
          true,
          new HttpResponse<Object>({ body: response, status: 200 }),
          '',
          []);
      });
  }

  protected updatePackagesForBranchesDummyResult(data?: any): Observable<NgxDataResult> {
    return this._http.get(this.baseUrl + '/getPackagesForBranches.Json')
      .map((response) => {
        return new NgxDataResult(
          true,
          new HttpResponse<Object>({ body: response, status: 200 }),
          '',
          []);
      });
  }

  getContinuousRulesForBranches(data?: any): Observable<NgxDataResult> {
    return this.getContinuousRulesForBranchesDummyResult(data)
      .delay(this.getConfigValue('delay'));
  }

  updateContinuousRulesForBranches(data?: any): Observable<NgxDataResult> {
    return this.updateContinuousRulesForBranchesDummyResult(data)
      .delay(this.getConfigValue('delay'));
  }

  protected getContinuousRulesForBranchesDummyResult(data?: any): Observable<NgxDataResult> {
    return this._http.get(this.baseUrl + '/getContinuousRulesForBranches.Json')
      .map((response) => {
        return new NgxDataResult(
          true,
          new HttpResponse<Object>({ body: response, status: 200 }),
          '',
          []);
      });
  }

  protected updateContinuousRulesForBranchesDummyResult(data?: any): Observable<NgxDataResult> {
    return this._http.get(this.baseUrl + '/getContinuousRulesForBranches.Json')
      .map((response) => {
        return new NgxDataResult(
          true,
          new HttpResponse<Object>({ body: response, status: 200 }),
          '',
          []);
      });
  }

  getInvoices(data?: any): Observable<NgxDataResult> {
    return this.createInvoiceDummyResult(data)
      .delay(this.getConfigValue('delay'));
  }

  protected createInvoiceDummyResult(data?: any): Observable<NgxDataResult> {
    return this._http.get(this.baseUrl + '/getInvoices.Json')
      .map((response) => {
        return new NgxDataResult(
          true,
          new HttpResponse<Object>({ body: response, status: 200 }),
          '',
          []);
      });
  }

  getInvoiceForDownload(data?: any): Observable<NgxDataResult> {
    return this.createDownloadInvoiceDummyResult(data)
      .delay(this.getConfigValue('delay'));
  }

  getFacebookAuthUrl(data?: any): Observable<NgxDataResult> {
    return this.createTwitterAuthUrlDummyResult(data)
      .delay(this.getConfigValue('delay'));
  }

  getMealplanPDF(data?: any): Observable<NgxDataResult> {
    return this.createTwitterAuthUrlDummyResult(data)
      .delay(this.getConfigValue('delay'));
  }

  getMealplanURLs(data?: any): Observable<NgxDataResult> {
    return this.createTwitterAuthUrlDummyResult(data)
      .delay(this.getConfigValue('delay'));
  }

  getStatisticDataInWeeks(data?: any): Observable<NgxDataResult> {
    return this.createTwitterAuthUrlDummyResult(data)
      .delay(this.getConfigValue('delay'));
  }
  getStatisticDataInDays(data?: any): Observable<NgxDataResult> {
    return this.createTwitterAuthUrlDummyResult(data)
      .delay(this.getConfigValue('delay'));
  }
  getStatisticDataInMonths(data?: any): Observable<NgxDataResult> {
    return this.createTwitterAuthUrlDummyResult(data)
      .delay(this.getConfigValue('delay'));
  }

  getDashboardStatistic(data?: any): Observable<NgxDataResult> {
    return this.createTwitterAuthUrlDummyResult(data)
      .delay(this.getConfigValue('delay'));
  }

  saveMealPlanPrint(data?: any): Observable<NgxDataResult> {
    return this.createTwitterAuthUrlDummyResult(data)
      .delay(this.getConfigValue('delay'));
  }

  saveMealPlanPrintPdf(data?: any): Observable<NgxDataResult> {
    return this.createTwitterAuthUrlDummyResult(data)
      .delay(this.getConfigValue('delay'));
  }

  updatePrintConfigByBranch(data?: any): Observable<NgxDataResult> {
    return this.createTwitterAuthUrlDummyResult(data)
      .delay(this.getConfigValue('delay'));
  }

  getPrintConfigByBranch(data?: any): Observable<NgxDataResult> {
    return this.createTwitterAuthUrlDummyResult(data)
      .delay(this.getConfigValue('delay'));
  }

  getPrintConfigByName(data?: any): Observable<NgxDataResult> {
    return this.createTwitterAuthUrlDummyResult(data)
      .delay(this.getConfigValue('delay'));
  }

  getPrintConfigNamesByBranch(data?: any): Observable<NgxDataResult> {
    return this.createTwitterAuthUrlDummyResult(data)
      .delay(this.getConfigValue('delay'));
  }

  saveBranchImage(data?: any): Observable<NgxDataResult> {
    return this.createTwitterAuthUrlDummyResult(data)
      .delay(this.getConfigValue('delay'));
  }

  deleteBranchImage(data?: any): Observable<NgxDataResult> {
    return this.createTwitterAuthUrlDummyResult(data)
      .delay(this.getConfigValue('delay'));
  }
  getManagerMessages(data?: any): Observable<NgxDataResult> {
    return this.createTwitterAuthUrlDummyResult(data)
      .delay(this.getConfigValue('delay'));
  }

  protected createFacebookAuthUrlDummyResult(data?: any): Observable<NgxDataResult> {
    const number: string = data;

    return this._http.get(this.baseUrl + '/Invoices/' + number + '.pdf',  { responseType: 'blob' } )
      .map((response) => {
        return new NgxDataResult(
          true,
          new HttpResponse<Object>({ body: response, status: 200 }),
          '',
          []);
      });
  }

  getTwitterAuthUrl(data?: any): Observable<NgxDataResult> {
    return this.createFacebookAuthUrlDummyResult(data)
      .delay(this.getConfigValue('delay'));
  }

  protected createTwitterAuthUrlDummyResult(data?: any): Observable<NgxDataResult> {
    const number: string = data;

    return this._http.get(this.baseUrl + '/Invoices/' + number + '.pdf',  { responseType: 'blob' } )
      .map((response) => {
        return new NgxDataResult(
          true,
          new HttpResponse<Object>({ body: response, status: 200 }),
          '',
          []);
      });
  }

  protected createWebSiteSnipDummyResult(data?: any): Observable<NgxDataResult> {
    return this._http.get(this.baseUrl + '/getSnip.Json')
      .map((response) => {
        return new NgxDataResult(
          true,
          new HttpResponse<Object>({ body: response, status: 200 }),
          '',
          []);
      });
  }

  protected createDownloadInvoiceDummyResult(data?: any): Observable<NgxDataResult> {
    const number: string = data;

    return this._http.get(this.baseUrl + '/Invoices/' + number + '.pdf',  { responseType: 'blob' } )
      .map((response) => {
        return new NgxDataResult(
          true,
          new HttpResponse<Object>({ body: response, status: 200 }),
          '',
          []);
      });
  }

  protected createDummyResult(data?: any): NgxDataResult {
    if (this.getConfigValue('alwaysFail')) {
      return new NgxDataResult(false,
        this.createFailResponse(data),
        null,
        ['Something went wrong.']);
    }
    return new NgxDataResult(true, this.createSuccessResponse(data), '/', ['Successfully logged in.']);
  }
  protected createDummyAuthResult(data?: any): NgxAuthResult {
    if (this.getConfigValue('alwaysFail')) {
      return new NgxAuthResult(false,
        this.createFailResponse(data),
        null,
        ['Something went wrong.']);
    }
    return new NgxAuthResult(true, this.createSuccessResponse(data), '/', ['Successfully logged in.']);
  }

  getMonday() {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    const day = date.getDay() || 7;

    if ( day !== 1 ) {
      date.setHours(-24 * (day - 1));
    }
    return date;
  }
}
